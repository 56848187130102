 <template>
      <div>
          <div v-if="$can(abilities.STATS_PROJECT)" class="row mb-10">
            <div class="col-12">
              <Card class="card shadow-sm p-10">
                <h2>{{ $t('projects.stats') }}</h2>
                <div class="row mt-5">
                  <div class="col-3" v-for="(stat, index) in projectStatusStat" :key="index">
                    <Card class="card p-5">
                      <div class="d-flex  align-items-center">
                        <span class="badge badge-square fs-2 p-5" :class="'badge-'+stat.color">{{stat.count}}</span>
                        <div class="fs-2 ms-5">{{stat[locale]}}</div>
                      </div>
                    </Card>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <Card :title="$t('projects.list')">
                <template #cardToolbar>
                  <ButtonDropdownFilter
                      :btn-title="$t('projects.label.cat')"
                      :options="[...categories]"
                      value-field="id"
                      name-field="name"
                      v-model="filterProject.project_category_id"
                  />
                </template>
                <CategoryGroupFilter v-model="filterProject.project_category_group_id"/>
                <div class="card-body">
                  <ProjectListView :filter="filterProject" @reloadDatatable='loadData' :action="false" />
                </div>
               
              </Card>
            </div>
          </div>
      </div>
 </template>
  <script setup>
  
  import Card from "@/components/Card.vue";
  import {useRoute} from "vue-router";
  import {useStore} from "vuex";
  import {useLocales} from "@/composables/locales";
  import {computed, ref, watch, onMounted} from "vue";
  import abilities from "@/composables/abilities";
  import ButtonDropdownFilter from "@/components/ButtonDropdownFilter.vue";
  import CategoryGroupFilter from "@/Modules/Projects/views/Projects/components/CategoryGroupFilter.vue";
  import ProjectListView from "@/Modules/Projects/views/Projects/ListView.vue";

  const route = useRoute()
  const store = useStore()
  const {locale} = useLocales()
  const props = defineProps({
    filters : {type: Object, default: {}},
  })

  const refreshKey = ref(0)

  const filterProject = ref({
    office: '',
    contact_id: route.params.id,
    project_category_id: '',
    project_category_group_id:'',
    reload: 0,
    ...props.filters
  })

  watch(() => route.params.id, (value) => {
    if(value){
      filterProject.value.contact_id = value
    }
  })

  watch(() => route.query.tab, (value) => {
    if(value == 'kt_ecommerce_customer_projects'){
      loadData()
    }
  }, { deep: true })

  const projectStatusStat = ref({})
  const getFistOffice = ref("")
  
  const offices = computed(() => (store.getters['offices/getSocietyOffices']))
  const currentCompany = computed(() => store.getters['offices/getSelectedCompany'])

  const categories = computed(() => store.getters['projectCategories/getProjectCategories'])

  watch(() => currentCompany.value, (value) => {
    if(value && route.query.tab == 'kt_ecommerce_customer_projects'){
     store.dispatch("offices/loadSocietyOffices", value?.id)
    }
  })

  watch(() => offices.value, (value) => {
    if(value && route.query.tab == 'kt_ecommerce_customer_projects'){
      getFistOffice.value = value[0]
    }
  })

  watch(() => getFistOffice.value, (value) => {
    if(value && route.query.tab == 'kt_ecommerce_customer_projects'){
      filterProject.value.office = getFistOffice.value?.id
    }
  })
  
  watch(() => [
    filterProject.value.start_date,
    filterProject.value.end_date,
    filterProject.value.contact_id,
  ], (newValue) => {
    if(route.query.tab == 'kt_ecommerce_customer_projects') {
      loadStat()
    }
  })

  async function loadStat() {
    if(filterProject.value.start_date && filterProject.value.end_date ){
      await store.dispatch('projects/getProjectStatusStat', filterProject.value).then((res) => {
        projectStatusStat.value = res.data
      })
    }
  }

  function loadData(){
    if(route.query.tab == 'kt_ecommerce_customer_projects'){
      refreshKey.value++
      filterProject.value.reload += 1
      loadStat()
    }
  }
  
  onMounted(async() => {
    if(route.query.tab == 'kt_ecommerce_customer_projects'){
      store.dispatch("projectCategories/loadProjectCategories")
      if(currentCompany.value){
        store.dispatch("offices/loadSocietyOffices", currentCompany.value?.id)
      }
      loadStat()
    }
  })
  
</script>

<style scoped>
  .table-container {
    height: 350px;
    overflow-y: auto;
  }

  thead th {
    position: sticky;
    top: 0;
    background-color: #f8f9fa;
    z-index: 1;
  }
</style>