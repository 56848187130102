<template>
  <div class="row">
    <div class="col-md-6 col-lg-3 mb-5">
      <div class="card card-body pt-5 me-lg-5" style="height: 80px;">
        <div class="d-flex flex-stack">
          <div class="symbol symbol-30px me-4">
            <div class="symbol-label fs-2 bg-primary text-white">
              {{contactStats.invoicesNotPaidCount}}
            </div>
          </div>
          <div class="flex-row-fluid flex-wrap">
            <div class="mb-2">
              <a class="text-gray-800 fs-6 fw-bolder">
                {{$t('contacts.no_paid_invoice')}}
              </a>
            </div>
            <h4 class="text-primary">{{formattedLocalAmount(contactStats.invoicesNotPaidTurnover)}} {{currency?.symbol}}</h4>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-lg-3 mb-5">
      <div class="card card-body pt-5 me-lg-5" style="height: 80px;">
        <div class="d-flex flex-stack">
          <div class="symbol symbol-30px me-4">
            <div class="symbol-label fs-2 bg-success text-white">
              {{contactStats.invoicesPaidCount}}
            </div>
          </div>
          <div class="flex-row-fluid flex-wrap">
            <div class="mb-2">
              <a class="text-gray-800 fs-6 fw-bolder">
                {{$t('contacts.paid_invoice')}}
              </a>
            </div>
            <h4 class="text-primary">{{formattedLocalAmount(contactStats.invoicesPaidTurnover)}} {{currency?.symbol}}</h4>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-lg-3 mb-5">
      <div class="card card-body pt-5 me-lg-5" style="height: 80px;">
        <div class="d-flex flex-stack">
          <div class="symbol symbol-30px me-4">
            <div class="symbol-label fs-2 bg-warning text-white">
              {{contactStats.invoicesValidatedCount}}
            </div>
          </div>
          <div class="flex-row-fluid flex-wrap">
            <div class="mb-2">
              <a class="text-gray-800 fs-6 fw-bolder">
                {{$t('contacts.progress_invoice')}}
              </a>
            </div>
            <h4 class="text-primary">{{formattedLocalAmount(contactStats.invoicesValidatedTurnover)}} {{currency?.symbol}}</h4>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-lg-3 mb-5">
      <div class="card card-body pt-5" style="height: 80px;">
        <div class="d-flex flex-stack">
          <div class="symbol symbol-30px me-4">
            <div class="symbol-label fs-2 bg-danger text-white">
              {{contactStats.invoicesCancelledCount}}
            </div>
          </div>
          <div class="flex-row-fluid flex-wrap">
            <div class="mb-2">
              <a class="text-gray-800 fs-6 fw-bolder">
                {{$t('contacts.cancel_invoice')}}
              </a>
            </div>
            <h4 class="text-primary">{{formattedLocalAmount(contactStats.invoicesCancelledTurnover)}} {{currency?.symbol}}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">{{ $t('invoice.list') }}</h3>
      </div>
      <div class="card-toolbar">
          <ButtonDropdownFilter
              :search="false"
              :options="invoiceStatus"
              value-field="value"
              :name-field="locale"
              v-model="filterInvoice.status"
          />
        <Button  v-if="$can(abilities.CREATE_INVOICE)" type="new" btn-class="btn-sm me-2" btn-text="invoice.create" @click="createInvoice" ></Button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <table class="table align-middle table-row-bordered fs-6 gy-3 gx-4"
              id="invoices_datatable">
          <thead>
          <tr class="fw-bold fs-6" style="background-color: #F2F3F4;">
            <th class="min-w-100px">{{ $t('contacts.invoice.number') }}</th>
            <th class="min-w-100px">{{ $t('contacts.invoice.totalAmount') }}</th>
            <th class="min-w-100px">{{ $t('contacts.invoice.date') }}</th>
            <th class="min-w-100px">{{ $t('contacts.invoice.status') }} </th>
            <th class="min-w-100px">{{ $t('label.action') }}</th>
          </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, nextTick, onMounted, ref, watch, shallowRef} from "vue";
import {useLocales} from "@/composables/locales";
import { useRouter, useRoute } from 'vue-router'
import {useStore} from "vuex";
import {BASE_URL} from "@/services/api";
import {formattedLocalAmount} from "@/mixins/Helpers";
import {useHasAbility} from "@/composables/hasAbility";
import Button from "@/components/Button.vue"
import ButtonDropdownFilter from "@/components/ButtonDropdownFilter.vue";
import abilities from "@/composables/abilities";
import {Theme} from "@/composables/theming";
import Ls from "@/services/ls";

const router = useRouter()
const route = useRoute()
const store = useStore()
const { can } = useHasAbility()

const {locale, datatableLocaleUrl} = useLocales()

const table = ref(null)

const props = defineProps({
  query: Object,
  currency: String
})

const filterInvoice = ref({status: 'all', ...props.query})
const invoiceStatus = computed(() => store.getters['invoices/invoiceStatus'])
const contactStats = computed(() => store.getters['contacts/getContactStats'])
const currency = computed(() => store.getters['company/getCompanyCurrency'])

watch(() => filterInvoice.value.status, (newValue) => {
  if(newValue && route.query.tab == 'kt_customer_stat'){
    table.value?.ajax.url(`${BASE_URL}/get/invoices/datatables?status=${filterInvoice.value.status}&contact=${route.params.id}&startDate=${filterInvoice.value.start_date}&endDate=${filterInvoice.value.end_date}`).load()
  }
})

watch(() => filterInvoice.value.start_date, (newValue) => {
  if(newValue && filterInvoice.value.start_date && filterInvoice.value.end_date && route.query.tab == 'kt_customer_stat'){
    table.value?.ajax.url(`${BASE_URL}/get/invoices/datatables?status=${filterInvoice.value.status}&contact=${route.params.id}&startDate=${filterInvoice.value.start_date}&endDate=${filterInvoice.value.end_date}`).load()
  }
}, { deep: true })

watch(() => locale.value, (value) => {
  if(route.query.tab == 'kt_customer_stat') {
    nextTick(() => {
      fetchDatas()
    })
  }
}, { deep: true })

watch(() => route.query.tab, (value) => {
  if(value == 'kt_customer_stat'){
    loadData()
  }
}, { deep: true })

watch(() => route.params.id, async (value, oldValue) => {
  if (value !== oldValue && route.name === 'contact-view-path' && route.query.tab == 'kt_customer_stat') {
    fetchDatas();
  }
});

onMounted(() => {
  loadData()
})

function loadData() {
  if(route.query.tab == 'kt_customer_stat'){
    store.dispatch('invoices/loadInvoiceStatus')
    fetchDatas()
    redirectDatatableAction()
  }
}

function getInvoiceStatusName(status) {
  const {color, fr, en} = invoiceStatus.value.find(el => el.value === status) || ''
  return {color, fr, en}
}

function fetchDatas() {
  if(!can(abilities.CONTACT_VIEW_INVOICE)) return
  const AUTH_TOKEN = Ls.get('auth.token')

  table.value = $("#invoices_datatable").DataTable({
    destroy: true,
    responsive: !0,
    searchDelay: 500,
    processing: !0,
    serverSide: !0,
    pageLength: Ls.get('pageLength') ?? 5,
    lengthMenu: [5, 10, 25, 50, 75, 100 ],
    order: [[ 1, 'asc']],
    ajax: {
      url: BASE_URL + `/get/invoices/datatables?status=${filterInvoice.value.status}&contact=${route.params.id}&startDate=${filterInvoice.value.start_date}&endDate=${filterInvoice.value.end_date}`,
      type: "GET",
      beforeSend: function (request) {
        request.setRequestHeader("Authorization", "Bearer " + AUTH_TOKEN);
        request.setRequestHeader("Company", Ls.get('current.company'));
      }
    },
    columns: [
      { data: "invoice_number" }, { data: "due_amount" }, { data: "date" }, { data: "invoice_status" }, { data: "Actions", responsivePriority: -1 }
    ],
    columnDefs: [

      {
        targets: -5,
        order: "desc",
        className: "dt-center clickable" ,
        render: function (t, a, e, l) {
          return '<span data-id="'+ e.id +'">' + t + "</span>";
        },
      },

      {
        targets: -4,
        order: "desc",
        className: "dt-center clickable" ,
        orderable: !1,
        render: function (t, a, e, l) {
          return '<span data-id="' + e.id + '">' + formattedLocalAmount(t) + ' ' + currency.value?.symbol +'</span>';
        },
      },

      {
        targets: -3,
        order: "desc",
        className: "dt-center clickable" ,
        render: function (t, a, e, l) {
          return '<span data-id="'+ e.id +'">' + t + "</span>";
        },
      },

      {
        targets: -2,
        orderable: false,
        className: "dt-center" ,
        render: function (t, a, e, l) {
          let status = getInvoiceStatusName(t)
          const locale = i18n.global.locale.value
          let color = status.color
          status = status[locale]
          return '<span class="badge badge-light-' + color +'" style="font-size:13px; margin:3px; padding:5px"><span style="margin-left: 5px" class="valueRow' + l.row + ' label label-success label-inline font-weight-bolder mr-2 edit_value_btn">' + status + '</span></span>'
        },
      },


      {
        width: "20%",
        targets: -1,
        "className": "dt-center",
        title: "Actions",
        orderable: !1,
        render: (t, a, e, l) => {
          let render=""
          if(can(abilities.VIEW_INVOICE) || can(abilities.VIEW_ALL_INVOICE)){
            render+='<a href="javascript:;" data-id="'+ e.id +'"  class="btn btn-sm btn-clean btn-icon show_btn" title="'+ i18n.global.t('button.show') +'"><i  data-id="'+ e.id +'" class="fas fa-eye fs-2" style="color: '+Theme.gray +'"></i></a>'
          }
          if(can(abilities.UPDATE_INVOICE) || can(abilities.UPDATE_ALL_INVOICE)) {
            if(e.invoice_status == 'DRAFT'){
              render+='<a href="javascript:;" data-id="'+ e.id +'"  class="btn btn-sm btn-clean btn-icon edit_btn" title="'+ i18n.global.t('button.edit') +'"><i  data-id="'+ e.id +'" class="fas fa-edit fs-2"  style="color: '+Theme.gray +'"></i></a>'
            }
          }

          return render
        },
      },

    ],
    language: {
      url: datatableLocaleUrl.value,
    }
  });

}

function redirectDatatableAction(){
  $(document).on("click", "#invoices_datatable .show_btn", (e) => {
    router.push({ name: 'invoices-view', params: { id: e.target.getAttribute('data-id') } })
  });

  $(document).on("click", "#invoices_datatable .edit_btn", (e) => {
    router.push({ name: 'invoices-edit', params: { id: e.target.getAttribute('data-id') } })
  });
}

function createInvoice(){
  router.push({ name: 'invoices-create' , query : { contact_id : route.params.id } })
}
</script>

<style scoped>
.card-min{
  height: auto!important;
}

.light-danger {
  color: #f1416c;
  border-color: #fff5f8;
  background-color: #fff5f8;
  font-size: .925rem;
  border-radius: 0.325rem;
  padding: 5px 10px;
}

.light-primary {
  color: #2DA333;
  border-color: #f1faff;
  background-color: #f1faff;
  font-size: .925rem;
  border-radius: 0.325rem;
  padding: 5px 10px;
}

.phone-card {
  background-color: #fff !important;
  color: #646E7B !important;
  border-radius: 10px;
  border: 1px solid #646E7B;
  width: 30px;
  height: 30px;
}

.phone-value {
  color: #222222 !important;
  background-color: #F2F3F4 !important;
}

.img-size {
  width: 110px !important;
  height: 110px !important;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
}

.contact-card {
  height: 330px !important;
  border-radius: 10px;
}

.contact-card:hover {
  border: 2px solid var(--bs-primary);
  border-radius: 10px;
}

.badge-light-primary {
    color: var(--bs-primary);
    background-color: var(--bs-light-primary);
}

.image-input [data-kt-image-input-action=change] {
    left: 80%;
    top: 0;
    margin-top: 15px;
}

.remove {
    position: absolute;
    left: 70%;
    top: 80%;
}

.icon-overlay {
    position: absolute;
    bottom: -10px !important;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    border-radius: 50%;
    padding: 8px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
