export const LOAD_PROJECTS = 'LOAD_PROJECTS'
export const CURRENT_PROJECT = 'CURRENT_PROJECT'
export const ADD_PROJECT_COMMENTS = 'ADD_PROJECT_COMMENTS'
export const ADD_PROJECT_COMMENT = 'ADD_PROJECT_COMMENT'
export const REMOVE_PROJECT_COMMENT = 'REMOVE_PROJECT_COMMENT'
export const ADD_PROJECT_DIRECTORIES = 'ADD_PROJECT_DIRECTORIES'
export const ADD_PROJECT_DIRECTORY = 'ADD_PROJECT_DIRECTORY'
export const ADD_PROJECT_STATUS = 'ADD_PROJECT_STATUS'
export const LOAD_REPORT_STATUS = 'LOAD_REPORT_STATUS'
export const LOAD_PROJECT_STEP_TASKS = 'LOAD_PROJECT_STEP_TASKS'
export const LOAD_CURRENT_PROJECT_LOGS = 'LOAD_CURRENT_PROJECT_LOGS'
export const ADD_BUDGET_TRANSACTION_TYPE = 'ADD_BUDGET_TRANSACTION_TYPE'
export const ADD_PROJECTS = 'ADD_PROJECTS'
export const LOAD_USER_PERFORM_CRITERIAS = 'LOAD_USER_PERFORM_CRITERIAS'
