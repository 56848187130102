import i18n from "@/plugins/i18n"
import abilities from "@/composables/abilities";
import moduleAbilities from "@/composables/moduleAbilities";
import { computed } from "vue";

const npsGroup = ['nps',"nps-list", "nps-create", "nps-views", "nps-preview"]
const prospectGroup = ['customer-list']

export default {
    menus:  computed(() => (
        {
            sectionTitle: i18n.global.t('menu.nps.title'),
            module : moduleAbilities.CUSTOMER_NPS,
            permission: abilities.VIEW_NPS,
            children: [
                {
                    title: i18n.global.t('menu.nps.survey'),
                    icon: 'fas fa-star icon-right2',
                    route: 'nps-list',
                    group: npsGroup,
                    id: 'step-nps',
                    permission: [abilities.VIEW_NPS],
                    children: [],
                    module: [moduleAbilities.CUSTOMER_NPS],
                },
                {
                    title: i18n.global.t('menu.prospect.title'),
                    icon: 'fas fa-user icon-right2',
                    route: 'customer-list',
                    group: prospectGroup,
                    id: 'step-nps',
                    permission: [abilities.VIEW_PROSPECT],
                    children: [],
                    module: [moduleAbilities.PROSPECT],
                }
            ]
        }
    ))
}
