import {componentLibraries as Components} from "./useComponentsLibrary.js";
import { useFormFieldTypes } from "./useFormFieldTypes.js";

export function useFormFieldBuilder(data = {}) {
    const { formFieldTypes } = useFormFieldTypes();
    let formData = {...data, isRequired: data.required}
    switch (data.type) {
        
        case formFieldTypes.RADIO:
            return {component: Components.FormRadioGroup, data:formData};
        case formFieldTypes.CHECKBOX:
            return {component: Components.FormCheckboxGroup, data:formData};
        case formFieldTypes.TEXTAREA:
            return {component: Components.FormTextArea, data:formData};
        default:
            return {component: Components.FormInput, data:formData};
    }

}