<template>
  <form v-show="modal" @submit.prevent="createContactSubmit" class="form">
    <div class="modal-content modal-dialog"  style="max-width: 800px;">
      <div class="modal-header">
        <h5 class="modal-title">{{$t("contacts.new_contact")}}</h5>
        <!--begin::Close-->
        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" @click="closeModal">
          <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
          <span class="svg-icon svg-icon-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
              <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
            </svg>
          </span>
          <!--end::Svg Icon-->
        </div>
        <!--end::Close-->
      </div>
        <div class="modal-body">
          <RequiredText />
          <div class="form">
            <div class="row mb-5">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <div class="col-9 col-form-label">
                        <!--begin::Radio-->
                        <div class="form-check form-check-custom form-check-inline mb-2">
                          <!--begin::Input-->
                          <input :id="'society'+id" class="form-check-input me-2 h-20px w-20px form-check-success" v-model="contactCreate.contactType" value="society" required type="radio" />
                          <label class="form-check-label me-5" :for="'society'+id">
                            <div class="text-gray-800"> {{$t("contacts.detail.society")}}</div>
                          </label>
                          <!--end::Input-->

                          <!--begin::Input-->
                          <input :id="'particular'+id" class="form-check-input  me-2 h-20px w-20px form-check-success" v-model="contactCreate.contactType" value="particular" required type="radio" />
                          <label class="form-check-label" :for="'particular'+id">
                            <div class="text-gray-800"> {{$t("contacts.detail.particular")}}</div>
                          </label>
                          <!--end::Input-->

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="contactCreate.contactType === 'particular'">
                  <Select2
                      :parent-id="'#'+id"
                      :is-required="true"
                      container-class="col-12 col-md-5 my-2"
                      :multiple="false"
                      :show-search="true"
                      :selectClass="'form-select form-control-lg form-select-solid'"
                      :name="'gender'+id"
                      :label="$t('user.gender')"
                      label-class="form-label"
                      :placeholder-text="$t('user.civility')"
                      v-model="contactCreate.gender"
                  >
                    <option value="female">{{ $t('user.female') }}</option>
                    <option value="male">{{ $t('user.male') }}</option>
                  </Select2>
                  <div class="col-12 col-md-5 my-2">
                    <InputDate
                        containerClass="mt-auto"
                        name="contacts_birthday"
                        :label="$t('contacts.birthday')"
                        label-class="form-label"
                        :inputClass="'form-control form-control-solid'"
                        :isRequired="false" size="lg"
                        :placeholder="$t('contacts.birthday')"
                        v-model="contactCreate.birthday"
                    />         
                  </div>  
                </div>

                <div class="row mb-5">
                  <div class="mt-2" :class="contactCreate.contactType === 'particular' ? 'col-md-5' : 'col-md-10'">
                    <label class="required mb-3">{{ contactCreate.contactType === 'particular' ? $t("contacts.lastname") :  $t("contacts.society_name") }}</label>
                    <input type="text" v-model="contactCreate.lastname" class="form-control form-control-solid" :placeholder="contactLastNamePlaceholder" required>
                  </div>
                  <div class="col-md-5 mt-2" v-if="contactCreate.contactType === 'particular'">
                    <label class="required mb-3">{{ $t("contacts.firstname") }}</label>
                    <input type="text" v-model="contactCreate.firstname" class="form-control form-control-solid" :placeholder="$t('contacts.firstname')" required>
                  </div>
                </div>
                <div class="row mb-5" v-if="contactCreate.contactType == 'society'">
                  <Select2
                      :parent-id="'#'+id"
                      :is-required="false"
                      container-class="col-12 col-md-10"
                      :multiple="true"
                      :show-search="true"
                      :selectClass="'form-select form-control-lg form-select-solid'"
                      :name="'collaborators'+id"
                      :label="$t('contacts.collaborators')"
                      label-class="form-label"
                      :placeholder-text="$t('contacts.select_collaborators')"
                      v-model="contactCreate.collaborators"
                  >
                    <option v-for="contact in contactParticuliars" :value="contact?.id" :key="contact?.id">
                        {{ contact.fullname }}
                    </option>
                  </Select2>
                </div>
                <div class="row" v-show="contactCreate.contactType == 'particular'">
                  <Select2
                      :parent-id="'#'+id"
                      :is-required="false"
                      container-class="col-12 col-md-5 mb-5"
                      :multiple="false"
                      :show-search="true"
                      :selectClass="'form-select form-control-lg form-select-solid'"
                      :name="'company'+id"
                      :label="$t('contacts.company')"
                      label-class="form-label"
                      :placeholder-text="$t('contacts.select_company')"
                      v-model="contactCreate.company_id"
                      :IsInputGroup="true"
                      icon="bi bi-plus"
                      :clickAddon="openContactModal"
                    >
                    <option v-for="contact in contactSocieties" :value="contact?.id" :key="contact?.id">
                        {{ contact.lastname }} {{ contact.firstname }}
                    </option>
                  </Select2>
                  <Select2
                      :parent-id="'#'+id"
                      container-class="col-12 col-md-5 mb-5"
                      :multiple="false"
                      :show-search="true"
                      selectClass="form-control form-select form-select-solid rounded-end-0 border-start"
                      :name="`post`+id"
                       :label="$t('contacts.post')"
                      :placeholderText="$t('contacts.post')"
                       v-model="contactCreate.post_id"
                      :IsInputGroup="true"
                      :is-required="false"
                      icon="bi bi-plus"
                      :clickAddon="openPostModal">
                      <option v-for="post in posts" :key="post?.id" :value="post?.id">{{ post?.title }}</option>
                  </Select2>
                </div>
                <div class="form-group row mt-4">
                  <div class="col-9 col-form-label">
                    <div class="form-check form-check-custom form-check-inline">
                      <!--begin::Input-->
                      <input :id="'prospect'+id" class="form-check-input me-2 h-20px w-20px form-check-success" v-model="contactCreate.prospect" type="checkbox" />
                      <label class="form-check-label me-5" :for="'prospect'+id">
                        <div class="text-gray-800"> {{$t("contacts.prospect")}}</div>
                      </label>
                      <!--end::Input-->

                      <!--begin::Input-->
                      <input :id="'customer'+id" class="form-check-input me-2 h-20px w-20px form-check-success" v-model="contactCreate.customer" value="society"  type="checkbox" />
                      <label class="form-check-label me-5" :for="'customer'+id">
                        <div class="text-gray-800"> {{$t("contacts.customer")}}</div>
                      </label>
                      <!--end::Input-->

                      <!--begin::Input-->
                      <input :id="'supplier'+id" class="form-check-input  me-2 h-20px w-20px form-check-success" v-model="contactCreate.supplier" value="particular"  type="checkbox" />
                      <label class="form-check-label me-5" :for="'supplier'+id">
                        <div class="text-gray-800"> {{ $t('contacts.supplier') }}</div>
                      </label>
                      <!--end::Input-->

                      <!--begin::Input-->
                      <input :id="'subcontractor'+id" class="form-check-input  me-2 h-20px w-20px form-check-success" v-model="contactCreate.subcontractor" value="particular"  type="checkbox" />
                      <label class="form-check-label me-5" :for="'subcontractor'+id">
                        <div class="text-gray-800" style="width: 75px;"> {{ $t('contacts.subcontractor') }}</div>
                      </label>
                      <!--end::Input-->

                      <!--begin::Input-->
                      <input :id="'partner'+id" class="form-check-input  me-2 h-20px w-20px form-check-success" v-model="contactCreate.partner" value="particular"  type="checkbox" />
                      <label class="form-check-label" :for="'partner'+id">
                        <div class="text-gray-800"> {{ $t('contacts.partner') }}</div>
                      </label>
                      <!--end::Input-->
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group" style="height: 100%; min-height: 200px;">
                  <div class="d-flex justify-content-center align-items-center" style="height: 100%;">
                    <ImageInput 
                      class="" 
                      v-model:image="contactCreate.previewAvatar" 
                      :model-image="contactCreate.previewAvatar" 
                      containerClass="mb-10 text-center" 
                      imageClass="rounded-circle" 
                    /> 
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-md-6">
                <Select2
                    :parent-id="'#'+id"
                    :is-required="false"
                    container-class="crow mb-5"
                    :multiple="false"
                    :show-search="true"
                    :selectClass="'form-select form-select-solid'"
                    :name="'country'+id"
                    :label="$t('auth.country')"
                    label-class="mb-3"
                    :placeholder-text="$t('auth.country')"
                    v-model="contactCreate.address.country"
                >
                  <option v-for="country in getCountries" :key="country?.id" :value="country?.id">{{ country.name }}</option>
                </Select2>
                <div class="row mb-5">
                  <div class="col-6">
                    <label class=" mb-3">{{$t('contacts.placeholder.city')}}</label>
                    <input type="text" v-model="contactCreate.address.city" class="form-control form-control-solid" :placeholder="$t('contacts.placeholder.city')">
                  </div>  
                  <div class="col-6">
                    <label class=" mb-3">{{ $t('contacts.placeholder.zipcode') }}</label>
                    <input type="text" v-model="contactCreate.address.zipcode" class="form-control form-control-solid" :placeholder="$t('contacts.placeholder.zipcode')">
                  </div>
                </div>
                <div class="row mb-5">
                  <div>
                    <label class="mb-3">{{ $t('contacts.placeholder.street') }}</label>
                    <input v-model="contactCreate.address.street" :placeholder="$t('contacts.placeholder.street')" type="text" class="form-control form-control-solid">
                  </div>
                </div>
                <div class="row mb-5">
                  <div>
                    <label class="mb-3">{{ $t('contacts.placeholder.street2') }}</label>
                    <input type="text" v-model="contactCreate.address.street2" :placeholder="$t('contacts.placeholder.street2')" class="form-control form-control-solid">
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="row mb-5">
                  <div class="col-12 col-md-6">
                    <label class="required mb-3">{{ $t('contacts.detail.currency') }}</label>
                    <select :data-dropdown-parent="'#'+id" data-search="true" :id="'currency'+id" :name="'currency'+id" :data-placeholder="$t('auth.currency')" class="form-select form-select-solid " data-control="select2" v-model="contactCreate.currency_id">
                      <option></option>
                      <option v-for="currency in getCurrencies" :key="currency?.id" :value="currency?.id">{{ currency.name + ' (' +  currency.iso + ')'}}</option>
                    </select>     
                  </div>
                  <div class="col-12 col-md-6">
                    <label class="required mb-3">{{$t("contacts.detail.exchange")}}</label>
                    <input v-model="contactCreate.exchange_rate" class="form-control form-control-solid"/>  
                  </div>  
                </div>
                <div class="row mb-5">
                  <div class="col-12">
                    <label class=" mb-3">{{$t("contacts.detail.email")}}</label>
                    <input v-model="contactCreate.email" class="form-control form-control-solid"/> 
                  </div>
                </div>

                <div class="row mb-5">
                  <div class="col-12">
                    <label class=" mb-3">{{$t("contacts.detail.website_link")}}</label>
                    <input v-model="contactCreate.website" class="form-control form-control-solid" :placeholder="$t('contacts.placeholder.website')" type="url"/>              
                  </div>
                </div>
                <div class="row mb-5">
                  <label class=" mb-3">{{$t("contacts.detail.phone")}}</label>
                  <input :id="'kt_tagify_telephone_' + id" class="ms-2 form-control tagify " type="text"/>
                  <small :id="'valueHelpBlock' + id" class="form-text text-warning"> {{$t('contacts.tag_input_text')}}   </small>               
                </div>

              </div>
              <div class="form-group row mt-4" v-if="isEmailValid">
                <div class="col-9 col-form-label">
                  <div class="form-check form-check-custom form-check-inline">
                    <!--begin::Input-->
                    <input :id="'portal'+id" class="form-check-input  me-2 h-20px w-20px form-check-success" v-model="contactCreate.enable_portal" value="true"  type="checkbox"  :disabled="!isEmailValid" />
                    <label class="form-check-label me-5" :for="'customer'+id">
                      <div class="text-gray-800 fw-bolder"> {{$t("contacts.portal")}}</div>
                    </label>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <Button type="cancel" id="cancel_btn" btn-class="btn-sm me-3" size="7px 30px" btn-text="button.cancel" @click="closeModal"></Button>
          <Button btn-class="btn-sm me-3" type="submit" id="submit_btn" btn-text="button.save" size="7px 30px" :is-loading="isLoading"></Button>
        </div>
    </div>
  </form>

</template>

<script setup>
import {computed, nextTick, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import ImageInput from "@/components/ImageInput.vue";
import RequiredText from "@/components/RequiredText"
import Select2 from "@/components/Select2.vue"
import Button from "@/components/Button.vue"
import InputDate from "@/components/InputDate2.vue";
import {createFormData} from "@/mixins/Helpers";

const store = useStore()
const emits = defineEmits(['success'])

const props = defineProps({
  contact: {type: Object, required: true},
  getCountries: {type: Array, required: false},
  getCurrencies: {type: Array, required: false},
  modal: {type: Boolean, default: false},
  edit: {type: Boolean, default: false},
  id: {type: String, default:"1" },
  parent: { type: String, required: false }
})

const email = ref(null)
const telephone = ref(null)
const isLoading = ref(false)
const contactCreate = ref(props.contact)

const contact = ref(props.contact)
const contacts = computed(() => store.getters['contacts/getContacts'])
const posts = computed(() => store.getters['post/getPost'])
const contactSocieties = computed(() => contacts.value.filter((contact) => contact.contactType == 'society'))
const contactParticuliars = computed(() => contacts.value.filter((contact) => contact.contactType == 'particular' && contact.company_id == null))


const contactLastNamePlaceholder = computed(() =>{
  if(contactCreate.value.contactType == 'particular'){
    return i18n.global.t('contacts.lastname')
  }else{
    return i18n.global.t('contacts.society_name')
  }
})

watch(() => contact.value, (value) => {contactCreate.value = contact.value})

watch(() => props.contact.post_id, (value) => {
  if(value){
    contactCreate.value.post_id = value
  }
})

watch(() => props.contact.company_id, (value) => {
  contactCreate.value.company_id = props.contact.company_id
})
async function reloadContacts() {
  await store.dispatch('contacts/loadContacts')
}

function openPostModal() {
  $('#'+props.id).modal('toggle')
 $('#postCreateModal').modal('toggle');
}

function openContactModal(){
  $('#'+props.id).modal('toggle')
  $('#companyCreateModal').modal('toggle');
}

function reloadPost() {
 store.dispatch('post/loadPosts')
}
onMounted(() => {
  reloadContacts()
  contactCreate.value = contact.value
  reloadPost()
  let today = new Date() ;
  let yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  $("#kt_hbd").flatpickr({
    dateFormat: "d-m-Y",
    maxDate: yesterday
  });

  KTApp.initSelect2()
  KTImageInput.init();

  $('#country'+ props.id).select2()

  $('body select[data-control="select2"]').select2()

  $('body select[data-control="select2"]').change((e) => {
    switch (e.target.name) {
      case 'country'+props.id :
        contactCreate.value.address.country = e.target.value
        break;
      case 'currency'+props.id :
        contactCreate.value.currency_id = e.target.value
        break;
      case 'gender'+props.id :
        contactCreate.value.gender = e.target.value
        break;
    }
  });

  nextTick(() => {  tagifyTelephone()})

})

function tagifyTelephone(){

  let eTelephone = document.getElementById("kt_tagify_telephone_" + props.id)
  if(eTelephone){
    telephone.value = new Tagify(eTelephone)

    telephone.value.on("invalid", function (e) {
      if(e.detail.data.__isValid == 'pattern mismatch'){
        window.toastr['error']('Telephone invalid')
      }
    })
        .on('add', () => {
          contactCreate.value.telephone = telephone.value.value.map(x => x.value)
        })
        .on("remove", () => {
          contactCreate.value.telephone = telephone.value.value.map(x => x.value)
        })
  }
}

function closeModal(){
  if(props.parent){
      $('#'+props.parent).modal('toggle')
  }
  $('#'+props.id).modal('toggle')
}


const isEmailValid = computed(() => {
  return contactCreate.value.email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contactCreate.value.email);
});

async function createContactSubmit(){
  isLoading.value = true
  if(contactCreate.value.contactType != 'particular'){
    contactCreate.value.firstname = null
    contactCreate.value.gender = null
    contactCreate.value.company_id = null
  }else{
    contactCreate.value.collaborators = null
  }
  let response = {}
  try{
    if(props.edit){
      response = await store.dispatch('contacts/updateContact',contactCreate.value)
    }else{
      response = await store.dispatch('contacts/createContact',contactCreate.value)
    }

    if (response.data.contact) {
      if (contactCreate.value.previewAvatar && contactCreate.value.previewAvatar instanceof File) {
        let avatarData = new FormData()
        avatarData.append('avatar', contactCreate.value.previewAvatar);
        avatarData.append('contact_id', response.data.contact.id);
        avatarData.append('delete_image', null);
        await store.dispatch('contacts/uploadAvatar', avatarData)
      }
      emits('success', response.data.contact)

      closeModal()
      contactCreate.value = {
        id : null,
        gender : null,
        contactType: 'particular',
        name: null,
        address: {
          street: null,
          street2: null,
          city: null,
          zipcode: null,
          country: null
        },
        customer: true,
        birthday: null,
        supplier: false,
        telephone: [],
        avatar: null,
        email:null,
        website: null,
        currency_id: '',
        post_id: null,
        enable_portal: null
      },
      window.toastr['success'](i18n.global.t('notification.contact_created'))

      isLoading.value = false
      return true
    }
  }catch(e){
    isLoading.value = false
  }
}
</script>

<style scoped>
    .theme-orange .vdatetime-popup__header,
    .theme-orange .vdatetime-calendar__month__day--selected > span > span,
    .theme-orange .vdatetime-calendar__month__day--selected:hover > span > span {
        background: #1e1e2d;
    }

    .theme-orange .vdatetime-year-picker__item--selected,
    .theme-orange .vdatetime-time-picker__item--selected,
    .theme-orange .vdatetime-popup__actions__button {
        color: #1e1e2d;
    }

    .image-input [data-kt-image-input-action=change] {
        left: 80%;
        top: 10%;
    }

    .image-input [data-kt-image-input-action=cancel], .image-input [data-kt-image-input-action=remove] {
        position: absolute;
        left: 80%;
        top: 90%;
    }
    .form-select, .form-control {
      width: 100%;
    }

    @media (max-width: 768px) {
      .d-flex {
        flex-direction: column;
      }

      .col-md-6, .col-md-5 {
        width: 100%;
      }

      .mb-md-0 {
        margin-bottom: 0 !important;
      }
    }
</style>
