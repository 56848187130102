<template>
      <div v-if="isLoading" class="content d-flex flex-column flex-column-fluid" id="kt_content2">
        <div class="container-xxl">
          <div class="row loader" style="text-align: center">
            <div class="spinner">
            </div>
            <div>{{ $t('loading') }}</div>
          </div>
        </div>
      </div>
      <div v-else>
        <slot />
      </div>
  </template>
  
  <script>
  export default {
    props: {
      isLoading: {
        type: Boolean,
        default: false
      },
    }
  }
  </script>
  
  <style>
  .spinner-overlay {
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .spinner {
    border: 6px solid #f3f3f3;
    border-top: 5px solid var(--bs-primary);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    margin-left: 48%;
    margin-top: 20%;
    margin-bottom: 10px;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  </style>
  