import * as types from './mutation-types'
import {BASE_URL} from "@/services/api";
import store from "@/store";
import {STORE_FAMILY_STATUS} from "./mutation-types";


export const getCurrentUser = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    window.axios.get('get/user').then((response) => {
      commit('user/' + types.BOOTSTRAP_CURRENT_USER, response.data.user, { root: true })
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const loadFamilyStatus = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    window.axios.get('get/family/status/collection').then((response) => {
      commit(types.ADD_FAMILY_STATUS, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const CreateFamilyStatus = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/family/status',data).then((response) => {
            commit(types.STORE_FAMILY_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadPostCategories = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    window.axios.get('get/post/categories/collection').then((response) => {
      commit(types.ADD_POST_CATEGORIES, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}


export const CreatePostCategories = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/post/category',data).then((response) => {
            commit(types.STORE_POST_CATEGORIES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const loadUsers = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    window.axios.get('get/users/collection').then((response) => {
      commit(types.ADD_USERS, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const createUser = ({ commit, dispatch, state }, data) => {
  let formData = new FormData();

  formData.append('gender', data.gender);
  formData.append('lastname', data.lastname);
  formData.append('firstname', data.firstname);
  formData.append('email', data.email);
  formData.append('phone_code', data.phone_code);
  formData.append('phone', data.phone);
  formData.append('role', data.role);
  formData.append('active', data.active);
  formData.append('department_id', data.department_id);
  formData.append('post_id', data.post_id);
  formData.append('avatar', data.avatar);
  formData.append('office_id', data.office_id);
  formData.append('delete_image', data.delete_image);
  formData.append('_method', 'POST');

  return new Promise((resolve, reject) => {
    axios.post('store/user', formData, data, {headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
      resolve(response)
        commit(types.ADD_USER, response.data.user)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const addInformationToUser = ({ commit, dispatch, state }, data) => {
  let formData = $objectToFormData(data)
  formData.append('_method', 'PATCH');

  return new Promise((resolve, reject) => {
    axios.post('user/'+data.id+"/info/update", formData).then((response) => {
      resolve(response)
        commit(types.ADD_USER, response.data.user)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const deleteUser = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    axios.delete('delete/' + data + '/user').then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const updateUser = ({ commit, dispatch, state }, data) => {
  let formData = new FormData();
  
  formData.append('gender', data.gender);
  formData.append('lastname', data.lastname);
  formData.append('firstname', data.firstname);
  formData.append('email', data.email);
  formData.append('phone_code', data.phone_code);
  formData.append('phone', data.phone);
  formData.append('role', data.role);
  formData.append('active', data.active);
  formData.append('department_id', data.department_id);
  formData.append('post_id', data.post_id);
  formData.append('avatar', data.avatar);
  formData.append('office_id', data.office_id);
  formData.append('delete_image', data.delete_image);
  formData.append('_method', 'PATCH');

  return new Promise((resolve, reject) => {
    axios.post('update/' + data.id + '/user', formData, data, {headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const changeUserStatus = ({ commit, dispatch, state }, user_id) => {
  return new Promise((resolve, reject) => {
    axios.patch('update/' + user_id + '/status').then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const editProfil = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post('user/profile/update', data ,{headers: {'Content-type': 'multipart/form-data'}} ).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const editPassword = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post('user/password/update', data  ).then((response) => {
      store.dispatch('auth/logout', true)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const getUserStore = ({commit, dispatch, state}, id) => {
    return new Promise((resolve, eject) => {
        window.axios.get('get/user/'+id+'/store').then((response) => {
            resolve(response)
        }).catch((err) => {
            eject(err)
        })
    })
}

export const addUserStore = ({commit, dispatch, state}, data) => {
    return new Promise((resolve, eject) => {
        window.axios.post('add/user/'+data.id+'/store', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            eject(err)
        })
    })
}

export const removeUserStore = ({commit, dispatch, state}, data) => {
    return new Promise((resolve, eject) => {
        window.axios.delete('delete/store/'+ data.store_id +'/user/'+ data.user_id).then((response) => {
            resolve(response)
        }).catch((err) => {
            eject(err)
        })
    })
}


export const importUser = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
      window.axios.post('import/users', data).then((response) => {
          resolve(response)
      }).catch((err) => {
          reject(err)
      })
  })
}

export const exportUser = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
      window.axios.get('export/users', { responseType: 'blob' }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'users.xlsx');
          document.body.appendChild(link);
          link.click();
          resolve(response)
      }).catch((err) => {
          reject(err)
      })
  })
}

export const downloadUserModel = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
      window.axios.get('download-user-model', { responseType: 'blob' })
      .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'model-user.xlsx');
          document.body.appendChild(link);
          link.click();
          resolve(response)
      }).catch((err) => {
          reject(err)
      })
  })
}

export const findUserById = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/${id}/user`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const assignTeam = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        axios.post('users/assignTeam', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const changeLanguage = ({ commit, dispatch, state }, locale) => {
  return new Promise((resolve, reject) => {
      axios.get(`/greeting/${locale}`).then((response) => {
          resolve(response)
      }).catch((err) => {
          reject(err)
      })
  })
}

export const createUserOnly = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    axios.post('store/user/only', data).then((response) => {
      resolve(response)
        commit(types.ADD_USER, response.data.user)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const companyInfo = ({commit, dispatch, state}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('general/information').then((response) => {
            commit(types.COMPANY_INFO, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

function $objectToFormData(obj, formData = new FormData(), parentKey = '') {
  if (obj && typeof obj === 'object' && !(obj instanceof File) && !(obj instanceof Blob)) {
      Object.entries(obj).forEach(([key, value]) => {
          const fullKey = parentKey ? `${parentKey}[${key}]` : key;
          
          if (Array.isArray(value)) {
              value.forEach((item, index) => {
                  objectToFormData(item, formData, `${fullKey}[${index}]`);
              });
          } else if (typeof value === 'object' && value !== null) {
              objectToFormData(value, formData, fullKey);
          } else {
              formData.append(fullKey, value);
          }
      });
  } else {
      formData.append(parentKey, obj);
  }
  return formData;
}