<template>
  <div class="modal fade" :id="id" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
    <form class="form w-100" id="kt_field_form" @submit.prevent="saveChange">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ form.name == "" ? $t('nps.form.add_field') : $t('nps.form.update_field') }}</h5>
            <div class="btn btn-type btn-sm btn-active-light-primary ms-2" @click="closeModal">
                <span class="svg-type svg-type-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)"
                          fill="currentColor" />
                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                          fill="currentColor" />
                  </svg> 
                </span>
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <Input
                  :name="`${id}-desc`"
                  type="text"
                  :label="$t('nps.form.label')"
                  :isRequired="true" size="lg"
                  v-model="formData.label"
                />
              </div>
              <div class="col-3">
                  <Select2
                      :parent-id="'#'+id"
                      :name="'type'"
                      :multiple="false"
                      :label="$t('nps.form.type')"
                      :isRequired="true"
                      v-model="formData.type"
                      >
                    <option v-for="type in types" :key="type.value" :value="type.value">
                      {{ type[locale] }}
                    </option>
                  </Select2>
              </div>
             
              <div class="col-9">
                <Input
                  :name="`${id}-desc`"
                  type="text"
                  :label="$t('nps.form.placeholder')"
                  :isRequired="true" size="lg"
                  v-model="formData.placeholder"
                />
              </div>
            </div>
            <TagInput v-if="showOpt" id="id" name="options" v-model="formData.options" help="nps.form.options" label="nps.form.options" type="text" required/>
            <br>
            <div class="d-flex flex-stack mb-8">
              <div class="me-5">
                <label class="fs-6 fw-bold">{{ $t('nps.form.required_field') }}</label>
                <div class="fs-7 fw-bold text-muted">{{$t('nps.form.required_text')}}</div>
              </div>
              <BooleanInput classname="m" name="required" v-model="formData.required" label="nps.form.required" :required="false"/>
            </div>
          </div>
          <div class="modal-footer">
            <Button type="cancel" id="cancel_btn" btn-class="btn-sm me-3" size="7px 30px" btn-text="button.cancel" @click="closeModal"></Button>
            <Button btn-class="btn-sm me-3" type="submit" id="submit_btn" btn-text="button.save" size="7px 30px" :loading="loading"></Button>
          </div>
        </div>
      </div>
    </form>
  </div>

</template>

<script setup>
/* eslint-disable */
import {computed, ref, defineProps, onMounted, watch, nextTick} from "vue";
import {useStore} from "vuex";
import {useLocales} from "../../../composables/locales";
import { useFormFieldTypes } from "../composable/useFormFieldTypes";
import TagInput from "./Inputs/TagInput.vue";
import BooleanInput from "./Inputs/BooleanInput.vue";
import Button from "@/components/Button.vue";
import Select2 from "@/components/Select2.vue";
import Input from "@/components/Input.vue";

const { formFieldTypes } = useFormFieldTypes()
const store = useStore()
const { locale } = useLocales()
const props = defineProps({
  form: {
    type: Object,
    default: {
      type: "",
      label: "",
      name: "",
      placeholder: "",
      required: true,
      options: [],
      extensions: [],
      repeatable: false,
      can_delete: true
    }
  },
  id: {
    type: String,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  }
})
const emits = defineEmits(['load'])
const formData = ref(props.form)

const types = computed(() => store.getters['nps/types'])

const showOpt = computed(() =>  ['radio', 'checkbox'].includes(formData.value.type))


watch(() => props.form, () => {
  if(props.form){
    formData.value = props.form

    desableInput()
  }

},{deep: true})

onMounted(() => {
  desableInput()
})

function closeModal(){
  $('#'+props.id).modal('toggle')
}

const saveChange = () => {
  emits('save', formData.value)
  closeModal()

}

function desableInput(){
  nextTick(() => {
    $('#type_select').prop('disabled', !props.form.can_delete);
  })
}

</script>

<style scoped>
.role {
  border: 1px solid #fff;
  box-shadow: 0 .1rem 1rem .25rem rgba(0, 0, 0, .05);
}
</style>