import * as types from './mutation-types'

export default {

    [types.ADD_PERMISSIONS] (state, permissions) {

        if(Array.isArray(permissions)){
            state.permissions = permissions
        }else {
            state.permissions = Object.values(permissions)
        }
    },
    [types.LOAD_PERMISSIONS] (state, permissions) {

        if(Array.isArray(permissions)){
            state.all = permissions
        }else {
            state.all = Object.values(permissions)
        }
    }

    
}
