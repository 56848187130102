<template>
  <div class="card  pt-4 mb-6 mb-xl-9">
    <form id="kt_ecommerce_settings_general_form" @submit.prevent="updateContactData" class="form" action="#">

      <div class="card-header border-0">
        <div class="card-title">
          <h2 class="fw-bolder mb-0">{{ $t('contacts.fiche_contact') }}</h2>
        </div>
        <div class="card-toolbar">
          <Button btn-class="btn-sm" v-if="$can(abilities.UPDATE_CONTACT)" type="new" btn-text="button.save" @click.prevent="updateContactData" :isLoading="isLoading"/>
        </div>
      </div>

      <div class="card-body" >
        <RequiredText />
        <div class="row mb-5">
          <div class="col-md-9">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <div class="col-9 col-form-label">
                    <div class="form-check form-check-custom form-check-inline mb-2">
                      <input  class="form-check-input me-2 h-20px w-20px form-check-success" v-model="contactForm.contactType" value="society" required type="radio" />
                      <label class="form-check-label me-5">
                        <div class="text-gray-800"> {{$t("contacts.detail.society")}}</div>
                      </label>
                      <input  class="form-check-input  me-2 h-20px w-20px form-check-success" v-model="contactForm.contactType" value="particular" required type="radio" />
                      <label class="form-check-label" >
                        <div class="text-gray-800"> {{$t("contacts.detail.particular")}}</div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column mt-5 mb-8 fv-row" v-if="contactForm.contactType === 'particular'">
              <div class="row">
                <div class="col-12 col-md-5" >
                  <label class="d-flex align-items-center fs-6 mb-2">
                    <span class="">{{$t('user.gender')}}</span>
                  </label>
                  <select required name="gender" class="form-select form-select-solid kt_select2"
                          v-model="contactForm.gender"  data-control="select2"
                          data-search="true"
                          :data-placeholder="$t('user.civility')" >
                    <option value="female">{{ $t('user.female') }}</option>
                    <option value="male">{{ $t('user.male') }}</option>
                  </select>
                </div>
                <div class="col-12 col-md-5">
                  <InputDate
                      containerClass="mt-auto"
                      name="contacts_birthday"
                      :label="$t('contacts.birthday')"
                      label-class="d-flex align-items-center fs-6 mb-2"
                      :inputClass="'form-control form-control-solid'"
                      :isRequired="false" size="lg"
                      :placeholder="$t('contacts.birthday')"
                      v-model="contactForm.birthday"
                  />         
                </div> 
              </div>
            </div>
            <div class="row mt-4 mb-5">
              <div :class="contactForm.contactType === 'particular' ? 'col-md-5' : 'col-md-10'">
                <div class="row">
                  <label class="required mb-1">{{ $t("contacts.lastname") }}</label>
                  <div class="form-group col-md-12">
                    <input type="text" v-model="contactForm.lastname" class="form-control form-control-solid" :data-placeholder="'contactNamePlaceholder'" required>
                  </div>
                </div>
              </div>
              <div class="col-md-5" v-if="contactForm.contactType === 'particular'">
                <div class="row">
                  <label class="required mb-1">{{ $t("contacts.firstname") }}</label>
                  <div class="form-group col-md-12">
                    <input type="text" v-model="contactForm.firstname" class="form-control form-control-solid" :data-placeholder="'contactNamePlaceholder'" required>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="contactForm.contactType == 'society'">
              <Select2
                  :is-required="false"
                  container-class="col-12 col-md-10 mb-5 m-2"
                  :multiple="true"
                  :show-search="true"
                  :selectClass="'form-select form-control-lg form-select-solid'"
                  name="collaborators"
                  :label="$t('contacts.collaborators')"
                  label-class="form-label"
                  :placeholder-text="$t('contacts.select_collaborators')"
                  v-model="contactForm.collaborators"
              >
                <option v-for="contact in contactParticuliars" :value="contact.id" :key="contact.id">
                    {{ contact.lastname }} {{ contact.firstname }}
                </option>
              </Select2>
            </div>
            <div class="row" v-if="contactForm.contactType == 'particular'">
              <Select2
                  :is-required="false"
                  container-class="col-12 col-md-10 mb-5 m-2"
                  :multiple="false"
                  :show-search="true"
                  :selectClass="'form-select form-control-lg form-select-solid'"
                  name="company"
                  :label="$t('contacts.company')"
                  label-class="form-label"
                  :placeholder-text="$t('contacts.select_company')"
                  v-model="contactForm.company_id"
                  :IsInputGroup="true"
                  icon="bi bi-plus"
                  :clickAddon="openContactModal"
              >
                <option v-for="contact in contactSocieties" :value="contact.id" :key="contact.id">
                    {{ contact.lastname }} {{ contact.firstname }}
                </option>
              </Select2>
          </div>

            <div class="row" v-if="contactForm.contactType == 'particular'">
              <Select2
        
                container-class="col-12 col-md-10 mb-5 m-2"
                :multiple="false"
                :show-search="true"
                selectClass="form-control form-select form-select-solid rounded-end-0 border-start"
                :name="`post`"
                :label="$t('contacts.post')"
                :placeholderText="$t('contacts.post')"
                v-model="contactForm.post_id"
                :IsInputGroup="true"
                :is-required="false"
                icon="bi bi-plus"
                :clickAddon="openPostModal">
                <option v-for="post in posts" :key="post?.id" :value="post?.id">{{ post?.title }}</option>
              </Select2>

            </div>
            <div class="form-group row mt-4">
              <div class="col-9 col-form-label">
                <div class="form-check form-check-custom form-check-inline">
                  <input :id="'prospect'+id" class="form-check-input me-2 h-20px w-20px form-check-success" v-model="contactForm.prospect"  type="checkbox" />
                  <label class="form-check-label me-5" :for="'prospect'+id">
                    <div class="text-gray-800"> {{$t("contacts.prospect")}}</div>
                  </label>
                  <input :id="'customer'+id" class="form-check-input me-2 h-20px w-20px form-check-success" v-model="contactForm.customer" value="society"  type="checkbox" />
                  <label class="form-check-label me-5" :for="'customer'+id">
                    <div class="text-gray-800"> {{$t("contacts.customer")}}</div>
                  </label>
                  <input :id="'supplier'+id" class="form-check-input  me-2 h-20px w-20px form-check-success" v-model="contactForm.supplier" value="particular"  type="checkbox" />
                  <label class="form-check-label me-5" :for="'supplier'+id">
                    <div class="text-gray-800"> {{ $t('contacts.supplier') }}</div>
                  </label>
                  <input :id="'subcontractor'+id" class="form-check-input  me-2 h-20px w-20px form-check-success" v-model="contactForm.subcontractor" value="particular"  type="checkbox" />
                  <label class="form-check-label me-5" :for="'subcontractor'+id">
                    <div class="text-gray-800" style="width: 75px;"> {{ $t('contacts.subcontractor') }}</div>
                  </label>
                  <input :id="'partner'+id" class="form-check-input  me-2 h-20px w-20px form-check-success" v-model="contactForm.partner" value="particular"  type="checkbox" />
                  <label class="form-check-label" :for="'partner'+id">
                    <div class="text-gray-800"> {{ $t('contacts.partner') }}</div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 mt-20">
            <div class="form-group row">
              <div class="col-lg-8">
                <ImageInput class="" v-model:image="contactForm.previewAvatar" :model-image="contactForm.previewAvatar" containerClass="mb-10 text-center" imageClass="rounded-circle"/>
              </div>
            </div>
          </div>
        </div>

        <div class="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
          <div class="col">
            <div class="fv-row mb-2">
              <label class="fs-6 fw-bold form-label mt-3">
                <span class="">{{ $t('contacts.email') }} :</span>
              </label>
              <input type="email" class="form-control form-control-solid" v-model="contactForm.email" />
            </div>
          </div>
          <div class="col">
            <div class="fv-row mb-2">
              <label class="fs-6 fw-bold form-label mt-3">
                <span>{{ $t('contacts.phone') }} :</span>
              </label>
              <input  :id="'kt_tagify_telephone_' + id" class="form-control tagify " type="text"/>
              <small :id="'valueHelpBlock' + id" class="form-text text-warning"> {{$t('contacts.placeholder.tag_input_text')}}   </small>
            </div>
          </div>
        </div>

        <div class="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
          <div class="col-sm-12 col-md-6">
            <label class="d-flex align-items-center fs-6 mb-2">
              <span class="required">{{$t('contacts.detail.currency')}}</span>
            </label>
            <select name="currency" class="form-select form-select-solid kt_select2"
                    v-model="contactForm.currency_id"  data-control="select2"
                    data-search="true"
                    :data-placeholder="$t('user.select_currency')" >
              <option v-for="currency in getCurrencies" :key="currency.id" :value="currency.id">{{ currency.name + ' (' +  currency.symbol + ')'}}</option>
            </select>
          </div>
          <div class="col-sm-12 col-md-6">
            <label class="required d-flex align-items-center fs-6 mb-2">{{$t("contacts.detail.exchange")}}</label>
            <input v-model="contactForm.exchange_rate" class="form-control form-control-solid"/> 
          </div>
        </div>

        <div class="row mb-5 mt-5">
          <div class="col-sm-12 col-md-6" v-if="contactForm.contactType === 'society'">
            <label class="fs-6 fw-bold form-label">
              <span>{{ $t('contacts.website') }}</span>
            </label>
            <input type="text" class="form-control form-control-solid" v-model="contactForm.website" />
          </div>
          <div class="col-sm-12" :class="contactForm.contactType === 'society' ? 'col-md-6' : 'col-md-12'" v-show="contactForm.contactType === 'particular'">
            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold form-label mt-3">
                <span>{{ $t('contacts.website') }} :</span>
              </label>
              <input type="url" :placeholder="$t('contacts.placeholder.website')" class="form-control form-control-solid" v-model="contactForm.website" />
            </div>
          </div>
        </div>

        <div class="form-group row mt-4" v-if="isEmailValid">
          <div class="col-9 col-form-label">
            <div class="form-check form-check-custom form-check-inline">
              <!--begin::Input-->
              <input :id="'portal'+id" class="form-check-input  me-2 h-20px w-20px form-check-success"
                    v-model="contactForm.enable_portal"
                    value="true"  type="checkbox"  :disabled="!isEmailValid" />
              <label class="form-check-label me-5" :for="'portal'+id">
                <div class="text-gray-800 fw-bolder"> {{$t("contacts.portal")}}</div>
              </label>

            </div>
          </div>
        </div>
        
        <div class="separator mb-6"></div>
        <div style="float: right;" class="mb-5">
          <Button btn-class="btn-sm" v-if="$can(abilities.UPDATE_CONTACT)" type="new" btn-text="button.save" @click.prevent="updateContactData" :isLoading="isLoading"/>
        </div>
      </div>
    </form>
  </div>

  <div class="modal fade" tabindex="-1" id="companyCreateModal">
    <div class="modal-dialog modal-xl">
      <create-contact id="companyCreateModal" :get-countries="getCountries" :get-currencies="getCurrencies" :contact="formCreateCompanyData" :modal="true"
        @success="loadContacts">
      </create-contact>
    </div>
  </div>

  <PostFormModal @postOffice="selectPosts" id="postCreateModal" ></PostFormModal>
</template>

<script setup>
import {computed, nextTick, onMounted, ref, watch, shallowRef} from "vue";
import { useRouter, useRoute } from 'vue-router'
import {useStore} from "vuex";
import Select2 from "@/components/Select2.vue";
import RequiredText from "@/components/RequiredText.vue"
import Button from "@/components/Button.vue"
import PostFormModal from "@/components/Post/postModal.vue"
import abilities from "@/composables/abilities";
import InputDate from "@/components/InputDate2.vue";
import ImageInput from "@/components/ImageInput.vue";
import {createFormData} from "@/mixins/Helpers";
import CreateContact from "@/components/contact/CreateContact";

const emits = defineEmits(['loadContactData'])

const props = defineProps({
  contact: Object
})

const router = useRouter()
const route = useRoute()
const store = useStore()

const id = ref(route.params.id)

const phones = shallowRef([])
const contactForm = ref({
  id : null,
  gender : '',
  contactType: null,
  lastname: null,
  firstname: null,
  customer: true,
  birthday: null,
  supplier: false,
  prospect: false,
  phones: [],
  email:null,
  website: null,
  currency_id: null,
  post_id : null,
  company_id: null,
  enable_portal: false,
  collaborators: [],
  exchange_rate: 1,
  partner: false,
  subcontractor: false,
  previewAvatar: null,
  avatar: null,
})
const isLoading = ref(false)
const initial_image = ref(null)
const myContact = ref(props.contact)

const formCreateCompanyData = ref({
  id: null,
  gender: null,
  contactType: 'society',
  lastname: null,
  firstname: null,
  address: {
    street: null,
    street2: null,
    city: null,
    zipcode: null,
    country: null
  },
  customer: true,
  birthday: null,
  supplier: false,
  telephone: [],
  previewAvatar: null,
  avatar: null,
  email: null,
  website: null,
  currency_id: '',
  post_id:null,
  company_id: null,
  enable_portal: false,
})

const getCountries = computed(() => store.getters['countries/getCountries'])
const posts = computed(() => store.getters['post/getPost'])
const getContacts = computed(() => store.getters['contacts/getContacts'])
const getCurrencies = computed(() => store.getters['company/getCurrencies'])
const contactSocieties = computed(() => getContacts.value.filter((contact) => contact.contactType == 'society'))
const contactParticuliars = computed(() => getContacts.value.filter((contact) => contact.contactType == 'particular' && (contact.company_id == null || contact.company_id == route.params.id)))

watch(() => contactForm.value.contactType, (value) => {
  if (value && route.query.tab == 'kt_ecommerce_customer_edit') {
   nextTick(() => {
     initSelect2()
    })
  }
})

watch(() => props.contact, (value, oldValue) => {
  if (value !== oldValue && route.query.tab == 'kt_ecommerce_customer_edit') {
    contactForm.value = {
      id:  props.contact?.id,
      avatar_url:  props.contact?.avatar_url ?  props.contact?.avatar_url : null,
      gender:  props.contact?.gender ?  props.contact?.gender : null,
      lastname:  props.contact?.lastname,
      firstname:  props.contact?.firstname,
      birthday:  props.contact?.birth ?  moment(new Date(props.contact?.birth)).format('DD-MM-YYYY') : null,
      phones:  props.contact?.phone,
      email:  props.contact?.email ?  props.contact?.email : null,
      contactType:  props.contact?.contactType ,
      customer:  props.contact?.customer ,
      supplier:  props.contact?.supplier ,
      prospect:  props.contact?.prospect ,
      enable_portal:  props.contact?.enable_portal ,
      website:  props.contact?.website ?  props.contact?.website : null,
      previewAvatar:  props.contact?.avatar_url ?? null,
      currency_id: props.contact?.currency_id,
      post_id: props.contact?.post_id,
      company_id: props.contact?.company_id,
      collaborators: props.contact?.collaborators?.map(item => item.id),
      exchange_rate: props.contact?.exchange_rate ? parseFloat(props.contact?.exchange_rate) : 1,
      partner: props.contact?.partner,
      subcontractor: props.contact?.subcontractor,
      avatar: null
    }
    initial_image.value = props.contact?.avatar_url ? props.contact?.avatar_url : null
    phones.value?.addTags(contactForm.value.phones)
  }
})


watch(() => route.query.tab, (value, oldValue) => {
  if (value == 'kt_ecommerce_customer_edit' && value !== oldValue) {
    loadData()
  }
})

onMounted(() => {
  if(route.query.tab == 'kt_ecommerce_customer_edit'){
    loadData()
  }
})

function loadData() {
  tagifyTelephone()
  KTImageInput.init()
  KTApp.initSelect2()
  initSelect2()
  reloadPost()
  loadCountries()
  loadCities()
  loadContacts()
  loadCurrencies()
  contactForm.value = {
    id:  props.contact?.id,
    avatar_url:  props.contact?.avatar_url ?  props.contact?.avatar_url : null,
    gender:  props.contact?.gender ?  props.contact?.gender : null,
    lastname:  props.contact?.lastname,
    firstname:  props.contact?.firstname,
    birthday:  props.contact?.birth ?  moment(new Date(props.contact?.birth)).format('DD-MM-YYYY') : null,
    phones:  props.contact?.phone,
    email:  props.contact?.email ?  props.contact?.email : null,
    contactType:  props.contact?.contactType ,
    customer:  props.contact?.customer ,
    supplier:  props.contact?.supplier ,
    prospect:  props.contact?.prospect ,
    enable_portal:  props.contact?.enable_portal ,
    website:  props.contact?.website ?  props.contact?.website : null,
    previewAvatar:  props.contact?.avatar_url ?? null,
    currency_id: props.contact?.currency_id,
    post_id: props.contact?.post_id,
    company_id: props.contact?.company_id,
    collaborators: props.contact?.collaborators?.map(item => item.id),
    exchange_rate: props.contact?.exchange_rate ? parseFloat(props.contact?.exchange_rate) : 1,
    partner: props.contact?.partner,
    subcontractor: props.contact?.subcontractor,
    avatar: null
  }
  initial_image.value = props.contact?.avatar_url ? props.contact?.avatar_url : null
  phones.value?.addTags(contactForm.value.phones)
}

function openContactModal(){
  $('#companyCreateModal').modal('toggle');
}

function selectPosts(id) {
  contactForm.value.post_id =id
}

const loadCountries = async () => {
  try {
    await store.dispatch('countries/loadCountries')
  }catch (e) {
  }
}

function reloadPost() {
 store.dispatch('post/loadPosts')
}

function openPostModal() {
 $('#postCreateModal').modal('toggle');
}

const loadCities = async () => {
  try {
    await store.dispatch('cities/loadCities')
  }catch (e) {
  }
}

const loadContacts = async (contact) => {
  if(contact){
    contactForm.value.company_id = contact.id
  }
  try {
    await store.dispatch('contacts/loadContacts')
  }catch (e) {
  }
}

const loadCurrencies = async () => {
  try {
    await store.dispatch('company/loadCurrencies')
  }catch (e) {
  }
}

function initSelect2() {
  $("body .kt_select2").select2()
  $('body select[data-control="select2"]').select2()

  $("body .kt_select2").change((e) => {
    switch (e.target.name) {
      case 'contact':
        router.push(`/admin/contact/${e.target.value}/view?tab=${route.query.tab}`)
        break;
      case 'gender' :
        contactForm.value.gender = e.target.value
        break;
      case 'currency' :
        contactForm.value.currency_id = e.target.value
        break;
      default:
        break;
    }
  });
}

const isEmailValid = computed(() => {
  return contactForm.value.email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contactForm.value.email);
});

async function updateContactData(){
  let response = {}

  isLoading.value = true

  if(contactForm.value.contactType == 'society'){
    contactForm.value.gender = ''
    contactForm.value.firstname = ''
    contactForm.value.birthday = null
  }

  try{
    response = await store.dispatch('contacts/updateContact',contactForm.value)
    myContact.value = {...response.data.contact, addresses: myContact.value.addresses}
    let data = {
      avatar: (contactForm.value.previewAvatar instanceof File) ? contactForm.value.previewAvatar : null,
      contact_id: contactForm.value.id,
      delete_image: initial_image.value && (contactForm.value.previewAvatar instanceof File || contactForm.value.previewAvatar == null) ? true : false
    }
    let avatarData = createFormData(data)
    let avatarResponse = await store.dispatch('contacts/uploadAvatar',avatarData)
    myContact.value.avatar_url = avatarResponse.data.avatar
    contactForm.value.previewAvatar = avatarResponse.data.avatar
    
    KTApp.initSelect2()
    initSelect2()
    emits('loadContactData')
    window.toastr['success'](i18n.global.t("notification.contact_updated"))
    isLoading.value = false
    return true
  }catch(err){
    isLoading.value = false
  }
}

function tagifyTelephone(){
  let eTelephone = document.getElementById("kt_tagify_telephone_" + id.value)
  if(eTelephone){
    phones.value = new Tagify(eTelephone)
    phones.value
      .on("invalid", function (e) {
        if(e.detail.data.__isValid == 'pattern mismatch'){
          window.toastr['error']('Telephone invalid')
        }
      })
      .on('add', () => {
        contactForm.value.phones = phones.value.value.map(x => x.value)
      })
      .on("remove", () => {
        contactForm.value.phones = phones.value.value.map(x => x.value)
      })
  }
}
</script>

<style scoped>
.card-min{
  height: auto!important;
}

.light-danger {
  color: #f1416c;
  border-color: #fff5f8;
  background-color: #fff5f8;
  font-size: .925rem;
  border-radius: 0.325rem;
  padding: 5px 10px;
}

.light-primary {
  color: #2DA333;
  border-color: #f1faff;
  background-color: #f1faff;
  font-size: .925rem;
  border-radius: 0.325rem;
  padding: 5px 10px;
}

.phone-card {
  background-color: #fff !important;
  color: #646E7B !important;
  border-radius: 10px;
  border: 1px solid #646E7B;
  width: 30px;
  height: 30px;
}

.phone-value {
  color: #222222 !important;
  background-color: #F2F3F4 !important;
}

.img-size {
  width: 110px !important;
  height: 110px !important;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
}

.contact-card {
  height: 330px !important;
  border-radius: 10px;
}

.contact-card:hover {
  border: 2px solid var(--bs-primary);
  border-radius: 10px;
}

.badge-light-primary {
    color: var(--bs-primary);
    background-color: var(--bs-light-primary);
}

.image-input [data-kt-image-input-action=change] {
    left: 80%;
    top: 0;
    margin-top: 15px;
}

.remove {
    position: absolute;
    left: 70%;
    top: 80%;
}

.icon-overlay {
    position: absolute;
    bottom: -10px !important;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    border-radius: 50%;
    padding: 8px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
