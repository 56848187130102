<template>
   <div class="card card-min pt-4 mb-6 mb-xl-9" v-if="contactAdress.addresses && contactAdress.addresses.length">
    <div class="card-header border-0">
      <div class="card-title">
        <h2 class="fw-bolder mb-0">{{ $t('contacts.address') }}</h2>
      </div>
      <div class="card-toolbar">
        <Button btn-class="btn-sm" v-if="$can(abilities.UPDATE_CONTACT)" type="new" btn-text="contacts.add_new_address" @click.prevent="openAddressModal('create')"/>
      </div>
    </div>
    <div id="kt_customer_view_payment_method" class="card-body pt-0" >
      <div  v-for="(address,key) in contactAdress.addresses" :key="key">
        <div class="py-0 px-5" data-kt-customer-payment-method="row" style="background-color: #0072CE0D; margin-bottom: 10px; border-radius: 8px;">
          <div class="py-1 d-flex flex-stack flex-wrap">
            <div class="d-flex align-items-center collapsible rotate" data-bs-toggle="collapse" :href="'#kt_customer_view_payment_method_' + key" role="button" aria-expanded="false" :aria-controls="'kt_customer_view_payment_method_' + key">
              <div class="me-3 rotate-90">
                  <span class="svg-icon svg-icon-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="currentColor" />
                    </svg>
                  </span>
              </div>
              <div class="me-3">
                <div class="d-flex align-items-center">
                  <div class="text-gray-800 fw-bolder me-2" v-if="address.address">{{ address.address }}</div>
                  <div class="text-gray-800 fw-bolder" v-if="address.city"> {{ address.city?.name }}</div>
                  <div class="text-gray-800 fw-bolder" v-if="address.country">,  {{ address.country?.name  }}</div>
                  <span v-if="address.default" class="badge badge-light-primary mx-10" >{{ $t('contacts.default') }}</span>
                </div>
              </div>
            </div>
            <div class="d-flex my-3 ms-9">
              <button v-if="$can(abilities.UPDATE_CONTACT)" class="btn btn-icon btn-active-light-primary w-30px h-30px me-3" @click.prevent="openAddressModal('edit', address)">
                <span data-bs-toggle="tooltip" data-bs-trigger="hover" :title="$t('button.edit')">
                  <i class="fa fa-edit"></i>
                </span>
              </button>
              <button v-show="!address.default" v-if="$can(abilities.UPDATE_CONTACT)" @click="contactSetDefaultAddress(address.id)" class="btn btn-icon btn-active-light-primary w-30px h-30px me-3" data-bs-toggle="tooltip" :title="$t('contacts.set_default_adress')" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                  <span class="svg-icon svg-icon-3">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="currentColor" />
                    <path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="currentColor" />
                  </svg>
                </span>
              </button>
              <button  @click="dropAddress(address.id)"  v-if="$can(abilities.UPDATE_CONTACT)" class="btn btn-icon btn-active-light-primary w-30px h-30px" data-bs-toggle="tooltip" :title="$t('button.delete')" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                <i class="fa fa-trash-alt"></i>
              </button>
              <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-150px py-3" data-kt-menu="true">
                <div class="menu-item px-3">
                  <a href="#" class="menu-link px-3" data-kt-payment-mehtod-action="set_as_primary">Set as Primary</a>
                </div>
              </div>
            </div>
          </div>
          <div :id="'kt_customer_view_payment_method_' + key" :class="key === 0 ? 'show' : ''" class="collapse  fs-6 ps-10" data-bs-parent="#kt_customer_view_payment_method">
            <div class="separator"></div>
            <div class="d-flex flex-wrap py-5">
              <div class="flex-equal me-5">
                <table class="table table-flush fw-bold gy-1">
                  <tbody>
                  <tr>
                    <td class="text-gray-800 min-w-125px w-125px">{{ $t('contacts.adress') }}</td>
                    <td class="text-muted">{{  address.address }}</td>
                  </tr>
                  <tr>
                    <td class="text-gray-800 min-w-125px w-125px">{{ $t('contacts.placeholder.zipcode') }}</td>
                    <td class="text-muted">{{ address.zipcode }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="flex-equal">
                <table class="table table-flush fw-bold gy-1">
                  <tbody>
                  <tr>
                    <td class="text-gray-800 min-w-125px w-125px">{{ $t('contacts.placeholder.city') }}</td>
                    <td class="text-muted">{{ address.city_id ? address.city?.name : "" }}</td>
                  </tr>

                  <tr>
                    <td class="text-gray-800 min-w-125px w-125px">{{ $t('auth.country') }}</td>
                    <td class="text-muted">{{ address?.country_id ?  address.country.name : ""  }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="card card-min" >
    <div class="card-body">
      <div class="card-px text-center ">
        <h2 class="fs-2x fw-bolder mb-0">{{ $t('contacts.no_address_found') }}</h2>
        <p class="text-gray-400 fs-4 fw-bold py-7">{{ $t('no_address_text') }}
          <br /></p>
        <Button btn-class="text-center btn-sm" v-if="$can(abilities.UPDATE_CONTACT)" type="new" btn-text="contacts.add_new_address" @click.prevent="openAddressModal('create')"/>
      </div>
      <div class="text-center pt-8 ">
        <Img filename="empty.png" alt="" class="mw-100 h-150px h-sm-125px" />
      </div>
    </div>
  </div>

  <CreateAddress
      id="1"
      :get-countries="getCountries"
      :address="form"
      :action="action"
      :contact="contactAdress.id"
      @addressCreated="contactAddressCreated"
      @addressUpdated="contactAddressUpdated"
  >
  </CreateAddress>
</template>

<script setup>
import {computed, ref} from "vue";
import { useRoute } from 'vue-router'
import {useStore} from "vuex";
import CreateAddress from "@/components/CreateAddress";
import Button from "@/components/Button.vue"
import abilities from "@/composables/abilities";
import Img from "@/components/Img.vue";
import {useSwal} from "@/composables/useSwal";

const props = defineProps({
  contact: Object
})

const route = useRoute()
const store = useStore()
const {showSwal} = useSwal()

const contactAdress = ref(props.contact)
const form = ref({
  id : null,
  gender : null,
  lastname: null,
  firstname: null,
  street: null,
  street2: null,
  city_id: null,
  zipcode: null,
  country: null
})

const action = ref('create')

const getCountries = computed(() => store.getters['countries/getCountries'])

function setAddressValue(address){
  form.value = {
    id : address?.id || null,
    gender : address?.gender || null,
    lastname: address?.lastname || null,
    firstname: address?.firstname || null,
    street: address?.address || null,
    street2:address?.addressComp || null,
    city_id: address?.city_id || null,
    zipcode: address?.zipcode || null,
    country: address?.country_id || null,
  }
}

function openAddressModal(act, address = null){
  action.value = act
  setAddressValue(address)

  $('#contactAddressModal1').modal('toggle')
}

function contactSetDefaultAddress(id){
  showSwal(
      '',
      i18n.global.t('contacts.set_default_adress')
  )?.then( async (result) => {
      if (result.value) {
        await store.dispatch('contacts/setDefaultAddress',{
          contact: props.contact.id,
          address: id
        })

        contactAddressUpdated({id:id, default: true})

        window.toastr['success'](i18n.global.t("notification.contact_default_address_seted"))
        store.dispatch('contacts/getContact',route.params.id).then((res) => {
          contactAdress.value = res.data.contact
        })
      }
  })
}

function dropAddress(id) {
  showSwal(
      i18n.global.t('swal.title'),
      i18n.global.t('swal.address')
  )?.then( async (result) => {
      if (result.value) {
        let response = await store.dispatch('contacts/deleteAddress', {
          contact: props.contact.id,
          address: id
        })

        let defaut = response.data.default

        let addresses = contactAdress.value.addresses || []
        addresses = addresses.filter(address => address.id !== id)

        if (defaut) {
          addresses = addresses.map(address => {
            if (defaut === address.id) {
              address.default = true
            }
            return address
          })
        }
        window.toastr['success'](i18n.global.t("notification.contact_address_delected"))

        contactAdress.value.addresses = addresses
      }
  })
}

function contactAddressCreated(address){
  let addresses = contactAdress.value.addresses || []
  addresses.push(address)
  contactAdress.value.addresses = addresses
}

function contactAddressUpdated(address){
  let addresses = contactAdress.value.addresses || []
  addresses = addresses.map(addr => {
    if(addr.id === address.id){
      return address
    }
    if (address.default){
      addr.default = false
    }
    return addr
  })
  contactAdress.value.addresses = addresses
}
</script>

<style scoped>
.card-min{
  height: auto!important;
}

.light-danger {
  color: #f1416c;
  border-color: #fff5f8;
  background-color: #fff5f8;
  font-size: .925rem;
  border-radius: 0.325rem;
  padding: 5px 10px;
}

.light-primary {
  color: #2DA333;
  border-color: #f1faff;
  background-color: #f1faff;
  font-size: .925rem;
  border-radius: 0.325rem;
  padding: 5px 10px;
}

.phone-card {
  background-color: #fff !important;
  color: #646E7B !important;
  border-radius: 10px;
  border: 1px solid #646E7B;
  width: 30px;
  height: 30px;
}

.phone-value {
  color: #222222 !important;
  background-color: #F2F3F4 !important;
}

.img-size {
  width: 110px !important;
  height: 110px !important;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
}

.contact-card {
  height: 330px !important;
  border-radius: 10px;
}

.contact-card:hover {
  border: 2px solid var(--bs-primary);
  border-radius: 10px;
}

.badge-light-primary {
    color: var(--bs-primary);
    background-color: var(--bs-light-primary);
}

.image-input [data-kt-image-input-action=change] {
    left: 80%;
    top: 0;
    margin-top: 15px;
}

.remove {
    position: absolute;
    left: 70%;
    top: 80%;
}

.icon-overlay {
    position: absolute;
    bottom: -10px !important;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    border-radius: 50%;
    padding: 8px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
