
import * as types from './mutation-types'


export const loadPermissions = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/permissions').then((response) => {
            commit(types.ADD_PERMISSIONS, response.data.permissions)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadAllPermissions = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/sub/module/permission').then((response) => {
            commit(types.LOAD_PERMISSIONS, response.data) 
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


