import * as types from './mutation-types'

export default {
  [types.LOAD_MAILS](state, data) {
    state.mails = data
  },
  [types.ADD_NEW_MAILS](state, data) {
    let mails = state.mails
    let dataToAdd = data.emails
    let dataToRemoveLength = dataToAdd.length;

    if(dataToRemoveLength > 10) {
      dataToAdd = dataToAdd.slice(0, 10);
    }

    if(parseInt(mails.current_page) === 1 && dataToRemoveLength > 0) {
      let mailsData = mails.data
      let newData = mailsData.slice(0, -dataToRemoveLength)
      mailsData = [...dataToAdd, ...newData]
      state.mails.data = mailsData
    }

    state.mails.total = state.mails.total + dataToRemoveLength
  },
  [types.DESTROY_MAIL](state, ids) {
    state.mails.data = state.mails.data.filter(item => !ids.includes(item.id));
  },
  [types.LOAD_USER_CONTACTS](state, data) {
    state.user_contacts = data
  },
  [types.LOAD_PRIORITIES](state, data) {
    state.priorities = data.data
  }
}
