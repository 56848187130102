<template>
  <div>
    <table class="table align-middle table-row-bordered fs-6 gy-3 gx-4" id="k_projects_datatable">
      <thead>
        <tr class="fw-bold fs-6" style="background-color: #F2F3F4;">
          <th class="min-w-100px text-center">{{ $t('projects.table.title') }}</th>
          <th class="min-w-100px text-center">{{ $t('projects.table.customer') }}</th>
          <th class="min-w-100px text-center">{{ $t('projects.table.start_at') }} </th>
          <th class="min-w-100px text-center">{{ $t('projects.table.end_at') }} </th>
          <th class="min-w-100px text-center">{{ $t('projects.table.keyword') }} </th>
          <th class="min-w-100px text-center">{{ $t('projects.table.progression') }} </th>
          <th class="min-w-100px text-center">{{ $t('label.action') }}</th>
        </tr>
      </thead>
    </table>
  </div>

  <AddUsersModal id="add_users_modal" @load='fetchDatas' :form="formAddUsers"/>
  <UpdateProjectModal id="update_project_modal" @reloadDatatable='fetchDatas' :form="formEdit" /> 
</template>
<script setup>
  import {nextTick, onMounted, ref, watch, defineProps} from "vue";
  import {useRoute, useRouter} from "vue-router";
  import {useStore} from "vuex";
  import {useLocales} from "@/composables/locales";
  import {useSwal} from "@/composables/useSwal";
  import {BASE_URL} from "@/services/api";
  import {Theme} from "@/composables/theming";
  import AddUsersModal from "./components/modals/AddUsersModal"
  import { useI18n } from 'vue-i18n'
  import UpdateProjectModal from "../../components/Modals/Project/UpdateProjectModal.vue"
  import Ls from "@/services/ls";
  import {useHasAbility} from "@/composables/hasAbility";
  import abilities from "@/composables/abilities";

  const route = useRoute()
  const router = useRouter()
  const store = useStore()
  const {locale, datatableLocaleUrl} = useLocales()
  const {showSwal} = useSwal()
  const { t } = useI18n({ useScope: 'global' })
  const { can } = useHasAbility()

  const emits = defineEmits(['reloadDatatable'])

  const props = defineProps({
    filter: {
      type: Object,
      required: true
    },
    keyNumber: String,
    action: {
      type: Boolean,
      required: true
    },
  })

  const table = ref(null)
  const isLoading = ref(true)
  const filterList = ref({
    status: '',
    start: 0,
    pageLength: 5
  })

  const formAddUsers = ref({
    project_id: '',
    users: []
  })

  const formEdit = ref({
    id: '',
    title: '',
    reference: '',
    start_date: '',
    end_date: '',
    description: '',
    progress: 0,
    key_word: [],
    creation_date: '',
    closing_date: '',
    contact_id: '',
    project_category_id: '',
    manager_id: '',
    office_id: '',
    budget_name: '',
    forecast_budget: '',
    currency_id: '',
    users: []
  })

  watch(() => locale.value, (value) => {
    nextTick(() => {
      if(route.query.tab == 'kt_ecommerce_customer_projects' || route.name === 'projects-index') fetchDatas()
    })
  }, { deep: true })

  watch(() => route.query.tab, (value) => {
    if(value == 'kt_ecommerce_customer_projects'){
      fetchDatas()      
      emits('reloadDatatable')
    }
  }, { deep: true })

  watch(() => [filterList.value.status, props.filter.start_date, props.filter.end_date,props.filter.project_category_group_id, props.filter.contact_id], () => {
    if(table.value && (route.query.tab == 'kt_ecommerce_customer_projects' || route.name === 'projects-index')){
      table.value?.ajax?.url(`${BASE_URL}/get/projects/datatables?status=${filterList.value.status}&startDate=${props.filter.start_date}&endDate=${props.filter.end_date}&project_category_group_id=${props.filter.project_category_group_id}&contact_id=${props.filter.contact_id}`).load()
    }else{
      if(route.query.tab == 'kt_ecommerce_customer_projects' || route.name === 'projects-index') fetchDatas
    }
  },{ deep :true})

  watch(() => props.filter.reload, (value, oldValue) => {
    if(value > oldValue){
      table.value?.ajax?.url(`${BASE_URL}/get/projects/datatables?status=${filterList.value.status}&startDate=${props.filter.start_date}&endDate=${props.filter.end_date}&project_category_group_id=${props.filter.project_category_group_id}&contact_id=${props.filter.contact_id}`).load()
    }
  })


  function fetchDatas() {
    const AUTH_TOKEN = Ls.get('auth.token')
    
    table.value = $("#k_projects_datatable").DataTable({
      destroy: true,
      responsive: !0,
      searchDelay: 500,
      processing: !0,
      serverSide: !0,
      pageLength: filterList.value.pageLength ?? Ls.get('pageLength'),
      lengthMenu: [5, 10, 25, 50, 75, 100 ],
      order: [[ 0, 'desc']],
      displayStart: filterList.value.start,
      ajax: {
        url: BASE_URL + `/get/projects/datatables?status=${filterList.value.status}&office_id=${props.filter.office}&startDate=${props.filter.start_date}&endDate=${props.filter.end_date}&project_category_group_id=${props.filter.project_category_group_id}&contact_id=${props.filter.contact_id}`,
        type: "GET",
        beforeSend: function (request) {
          request.setRequestHeader("Authorization", "Bearer " + AUTH_TOKEN);
          request.setRequestHeader("Company", Ls.get('current.company'));
        },
        complete: function (request) {
          if(request.status === 200) {
            request.responseJSON.start
            request.responseJSON.pageLength
            if (request.responseJSON && request.responseJSON.start) {
              filterList.value.start = request.responseJSON.start;
            }
            if (request.responseJSON && request.responseJSON.pageLength) {
              filterList.value.pageLength = request.responseJSON.pageLength; 
            }
          }

        }
      },
      columns: [
      { data: "title" }, { data: "contact" }, { data: "start_date" }, { data: "end_date" }, { data: "key_word" }, { data: "progress" }, { data: "Actions", responsivePriority: -1 }
      ],
      columnDefs: [
        {
          targets: -7,
          order: "desc",
          className: "dt-center text-center clickable" ,
          render: function (t, a, e, l) {
            return '<span data-id="'+ e.id +'">' + t + "</span>";
          },
        },
        {
          targets: -6,
          order: "desc",
          className: "dt-center text-center clickable" ,
          render: function (t, a, e, l) {
            return '<span data-id="'+ e.id +'">' + t + "</span>";
          },
        },
        {
          targets: -5,
          order: "desc",
          className: "dt-center text-center clickable" ,
          render: function (t, a, e, l) {
            return '<span data-id="'+ e.id +'">' + t + "</span>";
          },
        },
        {
          targets: -4,
          order: "desc",
          className: "dt-center text-center clickable" ,
          render: function (t, a, e, l) {
            let result = ''
            let end = (t == 'null' || t == 'undefined') ? JSON.parse(t) : t
            if (end !== null && end !== undefined) {
              result += '<span data-id="'+ e.id +'">' + t + "</span>";
            }
            return result
          },
        },
        {
          targets: -3,
          order: "desc",
          className: "dt-center text-center clickable" ,
          render: function (t, a, e, l) {
            let result = ''
            let keyword = t
            if (Array.isArray(keyword) && keyword.length) {
              for(let element of t) {
                result += '<span class="badge badge-light-primary" style="margin-right: 5px;">' + element + '</span>';
              }
            }
            return result
          },
        },
        {
          targets: -2,
          orderable: false,
          className: "dt-center text-center" ,
          render: function (t, a, e, l) {
            return '<span data-id="'+ e.id +'">' + t + "%</span>";
          },
        },
        {
          width: "10%",
          targets: -1,
          "className": "dt-center text-center",
          title: "Actions",
          orderable: !1,
          render: function (t, a, e, l) {
            let render = ""

            if(can(abilities.ADD_PROJECT_USER)) {
              render+='<a href="javascript:;" data-id="'+ e.id +'"  class="btn btn-sm btn-clean btn-icon show_btn" title="'+ i18n.global.t('button.view') +'"><i  data-id="'+ e.id +'" class="fas fa-eye fs-2" style="color: '+Theme.gray +'"></i></a>'
            }
            if(can(abilities.ADD_PROJECT_USER) && props.action) {
              render+='<a href="javascript:;" data-id="'+ e.id +'"  class="btn btn-sm btn-clean btn-icon add_users_btn" title="'+ i18n.global.t('projects.add_users') +'"><i  data-id="'+ e.id +'" class="fas fa-users fs-2" style="color: '+Theme.gray +'"></i></a>'
            }
            if(can(abilities.UPDATE_PROJECT) && props.action) {
              render+='<a href="javascript:;" data-id="'+ e.id +'"  class="btn btn-sm btn-clean btn-icon edit_btn" title="'+ i18n.global.t('button.edit') +'"><i  data-id="'+ e.id +'" class="fa fa-edit fs-2"  style="color: '+Theme.gray +'"></i></a>'
            }
            if(can(abilities.DELETE_PROJECT) && props.action)  {
              render+='<a href="javascript:;" data-id="'+ e.id +'"  class="btn btn-sm btn-clean btn-icon delete_btn" title="'+ i18n.global.t('button.delete') +'"><i  data-id="'+ e.id +'" class="fas fa-trash fs-2"  style="color: '+Theme.gray +'"></i></a>'
            }

            return render
          },
        },

      ],
      language: {
        url: datatableLocaleUrl.value,
      }
    });

    table.value.on("click", ".show_btn", (e) => {
      const target = e.target.closest('.show_btn');
      const id = target.getAttribute('data-id')
      router.push({name: 'projects-view', params: { id } })
    });
    $(document).on("click", "#k_projects_datatable .edit_btn", (e) => {
      let id = e.target.getAttribute('data-id')
      openUpdateModal(id)
    });
    $(document).on("click", "#k_projects_datatable .delete_btn", (e) => {
      let id = e.target.getAttribute('data-id')
      deleteProject(id)
    });

    $(document).on("click", "#k_projects_datatable .add_users_btn", async(e) => {
      let id = e.target.getAttribute('data-id')
      formAddUsers.value.project_id = id
      $('#add_users_modal').modal('toggle')
    });
  }

  function projectView(id) {
    router.push({name: 'view', params: { id } })
  }

  function openUpdateModal(id){
    store.dispatch('projects/showProject', {project_id: id})
        .then((res) => {
            let data = res.data.data
            if(data) {
              formEdit.value.id = data.id,
              formEdit.value.title = data.title,
              formEdit.value.reference = data.reference
              formEdit.value.start_date = moment(data.start_date).format('DD-MM-YYYY'),
              formEdit.value.end_date= data.end_date? moment(data.end_date).format('DD-MM-YYYY') : '',
              formEdit.value.description = data.description,
              formEdit.value.progress = parseFloat(data.progress),
              formEdit.value.key_word = (() => {
                  if (!data.key_word) return []; 
                  if (typeof data.key_word === "string") {
                      try {
                          return JSON.parse(data.key_word); 
                      } catch (error) {
                          return [data.key_word]; 
                      }
                  }
                  if (Array.isArray(data.key_word)) {
                      return data.key_word; 
                  }
                  return []; 
              })(),
              formEdit.value.contact_id = data.contact_id,
              formEdit.value.project_category_id = data.project_category_id,
              formEdit.value.manager_id = data.manager_id,
              formEdit.value.office_id = data.manager?.office_id
              formEdit.value.users =  data.users?.map(el => el?.id),
              formEdit.value.allow_dispatch = data?.budget?.allow_dispatch,
              formEdit.value.budget_name = data?.budget?.name,
              formEdit.value.forecast_budget = data?.budget?.forecast_budget,
              formEdit.value.currency_id = data?.budget?.currency?.id
            }
        }).finally(() => {
          $('#update_project_modal').modal('toggle')
        })
  }

  async function deleteProject(id){
    showSwal(
        t('projects.suppression'),
        t('projects.delete_project')
    ).then( async (result) => {
      if (result.value) {
        try {
          store.dispatch('projects/deleteProject', {project: id})
          .then(() => {
              fetchDatas()
              emits('reloadDatatable')
              window.toastr['success'](i18n.global.t('projects.project_deleted_successfully'))
          })
        } catch (e) {
        }
      }
    });
  }

  onMounted(() => {
    if(route.query.tab == 'kt_ecommerce_customer_projects' || route.name === 'projects-index') fetchDatas()
  })
</script>