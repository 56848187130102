import {computed} from "vue";
import i18n from '@/plugins/i18n';
import router from '../router/index'
import {
    differenceInDays,
    differenceInHours,
    differenceInMinutes, differenceInMonths,
    differenceInSeconds, differenceInWeeks,
    differenceInYears,
    formatDistance
} from 'date-fns'
import * as locales from 'date-fns/locale'
import { parse } from 'date-fns';
 
const locale = computed(() => i18n.global.locale)
export const appVersion = computed(() => process.env.VUE_APP_VERSION);
export const siteKey = computed(() => process.env.VUE_APP_RECAPTCHA_KEY);
export const secretKey = computed(() => process.env.VUE_APP_RECAPTCHA_PRIVATE_KEY);

export function formattedLocalAmount(amount) {
  const local = locale.value;
  const options = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  };

  const parsedAmount = parseFloat(amount);

  if (!isNaN(parsedAmount) && Number.isFinite(parsedAmount)) {
    if (Number.isInteger(parsedAmount)) {
      return parsedAmount.toLocaleString(local, options);
    } else {
      if (local === 'fr') {
        return parsedAmount.toLocaleString('fr-FR', options);
      } else {
        return parsedAmount.toLocaleString('en-US', options);
      }
    }
  }

  // Retourner la valeur d'origine si la conversion a échoué
  return amount;
}

export function $formatBirthdayDate(date){
  const dateObj = new Date(date);
  return dateObj.toLocaleDateString('fr-FR', { month: 'long', day: 'numeric' });
}

export function $formatDate(d) {
  var month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [day, month,year].join('/');
}

export function $dateFormatter(date, locale, long = false, includeTime = false, customOptions = null){
    const defaultOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    };

    const longOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };

    const timeOptions = {
        hour: 'numeric',
        minute: 'numeric',
    };

    let options = long ? longOptions : defaultOptions;

    if (includeTime) {
        options = { ...options, ...timeOptions };
    }

    return new Date(date).toLocaleDateString(locale, customOptions || options);
}

export function $dateFormatterWithDay(date, locale, includeTime = false, customOptions = null) {
    const match = date.match(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})$/);
    
    if (match) {
        const [, day, month, year, hour, minute, second] = match;
        date = `${year}-${month}-${day}T${hour}:${minute}:${second}`;
    }


    const defaultOptions = {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
    };

    const timeOptions = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    };

    let options = { ...defaultOptions };

    if (includeTime) {
        options = { ...options, ...timeOptions };
    }

    return new Date(date).toLocaleDateString(locale, customOptions || options).replace(',', '');
}


export function removeProtocol(url) {
    if (!url) {
      return ''
    }

    return url.replace(/(^\w+:|^)\/\//, '')
}

export function getUrlProtocol(url) {
    if (!url) {
      return ''
    }

    let arrayUrl = url.split('//')

    return arrayUrl[0]
}

export function $formatLocalAmount(amount, locale= 'fr') {
  // const locale = this.i18n.global.locale
  const options = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
  };

  const parsedAmount = parseFloat(amount);

  if (!isNaN(parsedAmount) && Number.isFinite(parsedAmount)) {
      if (Number.isInteger(parsedAmount)) {
          return parsedAmount.toLocaleString(locale, options);
      } else {
          if (locale === 'fr') {
              return parsedAmount.toLocaleString('fr-FR', options);
          } else {
              return parsedAmount.toLocaleString('en-US', options);
          }
      }
  }

  return amount;
}

export function $goBack() {
  router.go(-1)
}

export function formatDate(date) {
    if(!date){
        return ''
    }

    const d = new Date(date)
    var month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    return [day, month,year].join('-');

}

export function applyDiscount (discountType, discountVal, amount) {
    if (discountType !== 'amount') {
        return (parseFloat(amount) * parseFloat(discountVal) / 100)
    }
    return discountVal
}


export function getTenantId(){ 
  let host = window.location.host;
  let parts = host.split('.');
  let subDomainLength = 3; // without www
  let wwwSubDomainLength = 4; // with www

  if(window.location.hostname === 'localhost'){
    return 'app.'
  }

  if(parts.length === subDomainLength && parts[0] !== 'www'){
    return parts[0] + "."
  }else if(parts.length === wwwSubDomainLength && parts[0] === 'www'){
    return parts[1] + "."
  }

  if(parts[0] && parts[0] !== "www"){
    return parts[0] + "."
  }

  return window.location.hostname === 'localhost' ? 'app.' : ""
}

export function isSubDomain(){
    let host = window.location.host;
    host = host.replace('http://', '')
    host = host.replace('https://', '')
    host = host.replace('www.', '')
    host = host.replace(':'+process.env.VUE_APP_PORT, '')
    host = host.replace(process.env.VUE_APP_HOST, '')
    return !!host
}

export function getTenantReplacements(locale) {
    const tenant = getTenantId()?.slice(0, -1) 
  
    const tenantMessages = {
      default: {
        fr: { project: 'projet', projects: 'projets', category: "catégorie", categories: "catégories", manager: "Gestionnaire", categoryGroup:"tag",categoryGroups: "tags" },
        en: { project: 'project', projects: 'projects', category: "category", categories: "categories", manager: "Manager", categoryGroup:"tag",categoryGroups: "tag" },
      },
      adyk: {
        fr: { project: 'dossier', projects: 'dossiers', category: "nature de dossier", categories: "natures de dossier", manager: "Collaborateur",categoryGroup:"catégorie",categoryGroups:"catégories" },
        en: { project: 'folder', projects: 'folders', category: "nature of folder", categories: "natures of folder", manager: "Collaborator",categoryGroup:"category",categoryGroups:"categories" },
       },
    }
  
    return tenantMessages[tenant]?.[locale] || tenantMessages.default[locale];
}
  
export function formatRemainingTime(dateStr, locale= 'fr', suffix= true) {
  return formatDistance(new Date(dateStr), new Date(), { locale: locales[locale], addSuffix: suffix})
}

export function convertSeconds(seconds) {
    const currentTime = new Date();
    const pastTime = new Date(currentTime.getTime() - seconds * 1000);

    const diff = currentTime - pastTime;

    if (diff < 60000) {
        return `${i18n.global.t("justNow")}`;
    } else if (diff < 3600000) {
        const minutes = Math.floor(diff / 60000);
        return `${i18n.global.t("minutesAgo", {field: minutes})}`;
    } else if (diff < 86400000) {
        const hours = Math.floor(diff / 3600000);
        return `${i18n.global.t("hoursAgo", {field: hours})}`;
    } else if (diff < 604800000) {
        const days = Math.floor(diff / 86400000);
        return `${i18n.global.t("daysAgo",  {field: days})}`;
    } else if (diff < 2592000000) {
        const weeks = Math.floor(diff / 604800000);
        return `${i18n.global.t("weeksAgo", {field: weeks})}`;
    } else if (diff < 15552000000) {
        const months = Math.floor(diff / 2592000000);
        return `${i18n.global.t("monthsAgo", {field: months})}`;
    } else {
        const day = String(pastTime.getDate()).padStart(2, '0');
        const month = String(pastTime.getMonth() + 1).padStart(2, '0');
        const year = pastTime.getFullYear();
        return `${day}/${month}/${year}`;
    }
}

export function createFormData(data, formData = new FormData(), parentKey = '') {
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const fullKey = parentKey ? `${parentKey}[${key}]` : key;
            if (data[key] instanceof Object && !(data[key] instanceof File) ) {
                createFormData(data[key], formData, fullKey);
            } else {
                formData.append(fullKey, data[key]);
            }
        }
    }
    return formData;
}


export function addDay(dateString, day) {
  const [jour, mois, annee] = dateString.split('-').map(Number);
  const date = new Date(annee, mois - 1, jour);

  const dateFin = new Date(date);
  dateFin.setDate(dateFin.getDate() + day);

  const resultDay = dateFin.getDate().toString().padStart(2, '0');
  const resultMonth = (dateFin.getMonth() + 1).toString().padStart(2, '0');
  const resultYear = dateFin.getFullYear();

  return `${resultDay}-${resultMonth}-${resultYear}`;
}

export function formatTodayDate(){
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const year = today.getFullYear();
  const formattedStartDate = `${day}-${month}-${year}`;
  return formattedStartDate
}

export function compareDateToToday(start){
  if(start){
    const today = new Date();
    const startDate = parse(start, 'dd-MM-yyyy HH:mm', new Date());
    return startDate > today
  }
  return false
}

export function getClientTimezone(){
    return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function calculateByType (val, type, amount) {
    if (type !== 'amount') {
        return (parseFloat(amount) * parseFloat(val) / 100) ?? 0
    }
    return amount ?? 0
}

export const calculateEachItemValue = (baseVal = 0.0, content = {}) => {
    const isWithoutBracket = !content?.range_min && !content?.range_max
    if(isWithoutBracket){
        return calculateByType(baseVal, content.type, content.value);
    } else {
        let value = 0

        if (content?.range_min && !content?.range_max) {
            if(baseVal >= content?.range_min) {
                value = calculateByType(
                    baseVal - content?.range_min,
                    content?.type,
                    content?.value
                );
            }
        } else if (content?.range_min && content?.range_max) {
            if(baseVal <= content?.range_max && baseVal >= content?.range_min) {
                value = calculateByType(
                    baseVal - content?.range_min,
                    content?.type,
                    content?.value
                );
            } else if(baseVal > content?.range_max && baseVal >= content?.range_min) {
                value = calculateByType(
                    content?.range_max - content?.range_min,
                    content?.type,
                    content?.value
                );
            }
        } else if(!content?.range_min && content?.range_max) {
            if(baseVal <= content?.range_max) {
                value = calculateByType(
                    content?.range_max,
                    content?.type,
                    content?.value
                );
            }
            else {
                value = calculateByType(
                    content?.range_max - baseVal,
                    content?.type,
                    content?.value
                );
            }
        }

        return value
    }
}

export const calculatePayslipItemValue = (baseVal = 0.0, contents = []) => {
    let value = 0

    contents.forEach(item => {
        value += calculateEachItemValue(baseVal, item)
    })

    return value;
}

export function getMonthLocalName(month, year, locale) {
    const date = new Date(year+"-"+month+"-"+"01")

    const options = { month: "long" };

    if (isNaN(date.getTime())) {
        throw new Error("Invalid date parameters");
    }

    return new Intl.DateTimeFormat(locale, options).format(date)
}

const tenantId = getTenantId()?.slice(0, -1);
const basePath = "/assets/media/";

export const getImagePath = async (filename) => {
    const tenantImagePath = `${basePath}${tenantId}/${filename}`;
    const defaultImagePath = `${basePath}${filename}`;
      try {
        const response = await fetch(tenantImagePath, { method: "HEAD" });
        if(response.ok && response.headers.get("Content-Type")?.startsWith("image/") ){
            return tenantImagePath;
        }
        return defaultImagePath;
      } catch {
        return defaultImagePath;
      }
};

export function playCallSound() {
    if(!window.callAudio) {
        window.callAudio = new Audio('/assets/media/sounds/call-ringtone.mp3');
    }
    window.callAudio.loop = true;
    window.callAudio.play().catch(err => console.error("Erreur lors de la lecture du son :", err));
}

export function stopCallSound() {
    if(window.callAudio) {
        window.callAudio.pause();
        window.callAudio.currentTime = 0;
    }
}

export function groupByDate(array) {
    const grouped = {
        today: [],
        yesterday: [],
        other: {}
    };

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    array.forEach(item => {
        const itemDate = new Date(item.created_at);
        const itemDateStr = itemDate.toDateString();

        if (itemDate.toDateString() === today.toDateString()) {
            grouped.today.push(item);
        } else if (itemDate.toDateString() === yesterday.toDateString()) {
            grouped.yesterday.push(item);
        } else {
            if (!grouped.other[itemDateStr]) {
                grouped.other[itemDateStr] = [];
            }
            grouped.other[itemDateStr].push(item);
        }
    });

    const otherDates = Object.entries(grouped.other).map(([date, items]) => ({ date, items }));

    return {
        today: grouped.today,
        yesterday: grouped.yesterday,
        other: otherDates
    };
}


export function subText(txt = '', size = 15) {
    if (typeof txt !== 'string') return '';
    return txt.length > size ? `${txt.substring(0, size)} ...` : txt;
}

export function isToday(date) {
    const today = new Date()
    return new Date(date).toDateString() === today.toDateString()
}

export function getDateHour(date, locale) {
    return new Date(date).toLocaleTimeString(locale, {
        hour: '2-digit',
        minute: '2-digit'
    })
}

export const normaliserTexte = (str) => {
    return str
        .normalize('NFD') // Décompose les caractères accentués en caractères de base + diacritiques
        .replace(/[\u0300-\u036f]/g, '') // Supprime les diacritiques
        .toLowerCase()
        .replace(/[^a-z0-9\s-]/g, '')
        .trim()
        .replace(/\s+/g, '-');
}
export const getInitials = (str) => {
    if (!str) return "";

    const words = str.trim().split(/\s+/);
    return words.length > 1
        ? words.map(word => word[0].toUpperCase()).join('')
        : str[0].toUpperCase();
}
export function formatNotificationDate(date) {
    const nowDate = new Date();

    const diffSeconds = differenceInSeconds(nowDate, date);
    if (diffSeconds < 60) return `${diffSeconds}s`;

    const diffMinutes = differenceInMinutes(nowDate, date);
    if (diffMinutes < 60) return `${diffMinutes} min`;

    const diffHours = differenceInHours(nowDate, date);
    if (diffHours < 24) return `${diffHours} h`;

    const diffDays = differenceInDays(nowDate, date);
    if (diffDays < 7) return `${diffDays} j`;

    const diffWeeks = differenceInWeeks(nowDate, date);
    if (diffWeeks < 4) return `${diffWeeks} sem`;

    const diffMonths = differenceInMonths(nowDate, date);
    if (diffMonths < 12) return `${diffMonths} m`;

    return `${differenceInYears(nowDate, date)} a`;
}



export const $formatDateTime = (date) => {
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')

    return `${day}/${month}/${year} ${hours}:${minutes}`
}
