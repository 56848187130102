<template>
  <div class="h-100">
    <div class="d-flex flex-column flex-root align-items-stretch h-100">
      <div class="page d-flex flex-row align-items-stretch flex-column-fluid">
        <div id="kt_aside"
             class="aside aside-dark aside-hoverable"
             data-kt-drawer="true"
             data-kt-drawer-name="aside"
             data-kt-drawer-activate="{default: true, lg: false}"
             data-kt-drawer-overlay="true"
             data-kt-drawer-width="{default:'200px', '300px': '250px'}"
             data-kt-drawer-direction="start"
             data-kt-drawer-toggle="#kt_aside_mobile_toggle">

          <div class="aside-logo flex-column-auto" id="kt_aside_logo">
            <router-link :to="'/admin/dashboard'">
              <a href="#" >
                <Img filename="logo.png" class="h-25px logo" />
              </a>
            </router-link>

            <div @click.prevent="toggleAside" id="kt_aside_toggle" class="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
                 data-kt-toggle="true"
                 data-kt-toggle-state="active"
                 data-kt-toggle-target="body"
                 data-kt-toggle-name="aside-minimize">
              <span class="svg-icon svg-icon-1 rotate-180">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path opacity="0.5"
                        d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z"
                        fill="currentColor" />
                  <path
                      d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z"
                      fill="currentColor" />
                </svg>
              </span>
            </div>
          </div>


          <app-menu2 v-if="tenantId == 'adyk'"></app-menu2>
          <app-menu v-else></app-menu>
        </div>

        <div class="wrapper d-flex flex-column flex-row-fluid custom-back" id="kt_wrapper">
          <app-header :numberNotification="getNotificationCount"></app-header>
          <router-view :key="getSelectCompanyId" v-show="getSelectCompanyId && !isAppLoaded"/>
          <app-footer></app-footer>
          <NotificationDrawer  :notifications="getNotifications"/>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import AppFooter from './partials/AppFooter.vue'
import AppHeader from './partials/AppHeader.vue'
import AppMenu from './partials/AppMenu.vue'
import AppMenu2 from './partials/AppMenu2.vue'
import {mapGetters, mapActions, useStore} from "vuex";
import Ls from "@/services/ls";
import {useLocales} from "@/composables/locales";
import Img from '@/components/Img.vue';
import { getTenantId } from '@/mixins/Helpers'
import NotificationDrawer from "@/components/Notification/Notification.vue";

export default {
  data() {
    const { setLocale, locale } = useLocales()
    return {
      notifications: [],
      formCreateData: {
        object: '',
        value: '',
        description: '',
      },
      getSupportObjets : [],
      getObjetValues : [],
      filteredObjectValuesData: [],
      setLocale,
      locale
    }
  },

  components: {
    NotificationDrawer,
    AppFooter, AppHeader, AppMenu, Img, AppMenu2
  },

  computed: {
    ...mapGetters('permission', [
      'permissions'
    ]),

    ...mapGetters('notifications',[
      'getNotificationCount',
      'getNotifications',
    ]),

    ...mapGetters(['isAppLoaded']),

    ...mapGetters('offices' , [
        'getSelectCompanyId'
    ]),


    tenantId(){
      return getTenantId()?.slice(0, -1)
    },

  },


  updated() {
    this.$nextTick(() => {
      this.initComponents()
    })
  },

  async mounted() {
    await this.loadNotifications()
    await axios.get('push-notification/key')
        .then((response) => {
          this.publicKey = response.data.key
        })
        .catch((error) => {
          console.log(error)
        })

    if (('Notification' in window) && ('serviceWorker' in navigator))  {
      await this.AskPermissionToNotify()
    }

    this.handleLocale()
    await this.loadPermissions()
    await this.loadModules()
    await  this.listenNewNotification()

    await this.getCurrentUser()
    await this.loadOfficesBySocieties()
    this.initComponents()

  },

  methods: {
    ...mapActions('offices', [
      'loadOfficesBySocieties'
    ]),

    ...mapActions('permission', [
      'loadPermissions'
    ]),
    ...mapActions('moduleAccess', [
      'loadModules'
    ]),
    ...mapActions('user', [
      'getCurrentUser'
    ]),

    ...mapActions(['toggleAsideState']),

    ...mapActions('notifications',[
      'loadNotifications',
      'listenNewNotification',
    ]),


    initComponents(){
      KTMenu.init();
      KTDrawer.init();
      KTToggle.init()
      KTUtil.init()
      KTApp.init()
    },

    handleLocale() {
      const locale = Ls.get('locale')
      if(locale) {
        this.setLocale(locale)
      }
    },

    toggleAside() {
      this.toggleAsideState()
    },

    async AskPermissionToNotify() {
      if (Notification.permission !== "granted") {
        this.permissionPushNotification = await Notification.requestPermission()
      }else{
        this.permissionPushNotification = Notification.permission
      }

      if (this.permissionPushNotification === "granted") {
        let registration = await navigator.serviceWorker.getRegistration()
        let subscription = await registration.pushManager.getSubscription()
        if (!subscription) {
          subscription = registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: this.publicKey
          })
          subscription.then(async (value) => {
            await this.saveSubscription(value)
          })
        }else{
          await this.saveSubscription(subscription)
        }
      }
    },

    async saveSubscription(subscription) {
      await window.axios.post('/push-notification/saveSubscription', subscription.toJSON())
    }

  }

}
</script>

<style>

</style>

