import * as types from './mutation-types'
import {createFormData} from "@/mixins/Helpers";

function createMailFormData(data) {
    let formData = new FormData()

    formData.append('subject', data.subject)
    formData.append('message', data.message)
    formData.append('folder', data.folder)
    formData.append('priority', data.priority)
    formData.append('request_receipt', data.request_receipt)
    formData.append('scheduled_at', data.scheduled_at)

    if(data.to) {
        data.to.forEach((x) => formData.append('to[]', x))
    }

    if(data.cc) {
        data.cc.forEach((x) => formData.append('cc[]', x))
    }

    if(data.bcc) {
        data.bcc.forEach((x) => formData.append('bcc[]', x))
    }

    if(data.attachments) {
        data.attachments.forEach((x) => formData.append('attachments[]', x))
    }

    return formData
}

function createTransferMailFormData(data) {
    let formData = new FormData()

    formData.append('subject', data.subject)
    formData.append('message', data.message)
    formData.append('folder', data.folder)
    formData.append('priority', data.priority)
    formData.append('request_receipt', data.request_receipt)
    formData.append('scheduled_at', data.scheduled_at)

    if(data.to) {
        data.to.forEach((x) => formData.append('to[]', x))
    }

    if(data.cc) {
        data.cc.forEach((x) => formData.append('cc[]', x))
    }

    if(data.bcc) {
        data.bcc.forEach((x) => formData.append('bcc[]', x))
    }

    if(data.newAttachments) {
        data.newAttachments.forEach((x) => formData.append('newAttachments[]', x))
    }

    if(data.attachments) {
        /*data.attachments.forEach((x) => formData.append('attachments[]', JSON.stringify(x)))*/
        data.attachments.forEach((x) => {
            formData.append('attachments['+ x.id +'][name]',  x.name)
            formData.append('attachments['+ x.id +'][url]',  x.url)
        })
    }

    return formData
}

export const loadMails = ({ commit }, data) => {
    
    return new Promise((resolve, reject) => {
        window.axios.get('get/emails', {params: data}).then((response) => {
            commit(types.LOAD_MAILS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadPriorities = ({ commit }) => {
    
    return new Promise((resolve, reject) => {
        window.axios.get('get/email/priorities').then((response) => {
            commit(types.LOAD_PRIORITIES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadUsersAndContacts = ({ commit }) => {
    
    return new Promise((resolve, reject) => {
        window.axios.get('get/user/contact/email').then((response) => {
            commit(types.LOAD_USER_CONTACTS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getMail = ({ commit }, data) => {
    
    return new Promise((resolve, reject) => {
        window.axios.get('get/folder/email', {params: data}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const restoreMail = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('restore/email', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const replyMail = ({ commit }, data) => {
    let formData = createMailFormData(data)

    return new Promise((resolve, reject) => {
        window.axios.post('emails/reply/' + data.email_id, formData).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const transferMail = ({ commit }, data) => {
    let formSubmit = createTransferMailFormData(data)
    return new Promise((resolve, reject) => {
        window.axios.post('transfer/email/' + data.email_id, formSubmit).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createMail = ({commit}, data) => {
    let formData = createMailFormData(data)

    return new Promise((resolve, reject) => {
        window.axios.post('send-email', formData).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const markAsRead = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('mark/asRead/email', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const markAsUnread = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('mark/asUnread/email', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const markAsImportant = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('mark/important/email', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const markAsNotImportant = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('mark/not/important/email', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const changeMailFolder = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('email/changeFolder', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteTrashMail = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('delete/email/to/Trash', data).then((response) => {
            commit(types.DESTROY_MAIL, data.email_ids)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteMail = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('delete/email', data).then((response) => {
            commit(types.DESTROY_MAIL, data.email_ids)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getUserConfig = ({ commit }) => {
    
    return new Promise((resolve, reject) => {
        window.axios.get('get/user/imap/configs').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const setUserConfig = ({ commit }, data) => {
    
    return new Promise((resolve, reject) => {
        window.axios.post('set/user/imap/configs', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getImapConfig = ({ commit }) => {
    
    return new Promise((resolve, reject) => {
        window.axios.get('get/imap-config/collection').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const setImapConfig = ({ commit }, data) => {
    
    return new Promise((resolve, reject) => {
        window.axios.post('store/imap-config', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}