import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import abilities from "@/composables/abilities";

import store from '@/store/index'

import {UPDATE_APP_LOADING_STATUS} from '@/store/mutation-types'

import LayoutBasicView from '@/views/Layouts/LayoutBasic'

import LayoutLoginView from '@/views/Auth/LayoutLogin.vue'
import ConnexionView from '@/views/Auth/Connexion.vue'
import ForgotPasswordView from '@/views/Auth/ForgotPassword.vue'
import CreatePasswordView from '@/views/Auth/CreatePassword.vue'
import ResetPasswordView from '@/views/Auth/ResetPassword.vue'
import CreateAccountView from '@/views/Auth/Register/Index.vue'
import CreateAppointmentView from '@/views/CreateAppointmentView.vue'
import PermissionDeniedView from '@/views/Auth/PermissionDenied.vue'
import NotFoundPageView from '@/views/NotFoundPage.vue'

import ContactListView from '@/views/Contact/Index'
import ContactDetailView from '@/views/Contact/View'
import {isSubDomain} from "@/mixins/Helpers";

import CrmRoutes from "@/Modules/CRM/router/index"
import FinancialRoutes from "@/Modules/Financial/router/index"
import RhRoutes from "@/Modules/RH/router/index"
import FmRoutes from "@/Modules/FileManager/router/index"
import NpsRoutes from "@/Modules/Nps/router/index"
import CustomerRoutes from "@/Modules/Customer/router/index"
import ContactView from '@/Modules/Nps/views/Nps/ContactView.vue';
import ProjectsRoutes from "@/Modules/Projects/router/index"
import ChatsRoutes from "@/Modules/Chat/router/index"
import MailRoutes from "@/Modules/Mail/router/index"
import feedRoutes from '@/Modules/NewsFeed/router/index'
import moduleAbilities from "@/composables/moduleAbilities";

const checkPermission = async (permissions) => {
    let hasAccess = false;
    if(typeof permissions !== 'object')  permissions = [permissions]
    for(let permission of permissions ){
        if (!hasAccess) {
            await axios
                .get(`/permission/${permission}`)
                .then(res => {
                    hasAccess = res.data?.hasPermission
                })
                .catch(() => {
                    hasAccess = false
                })
        }
    }

    return hasAccess
}

const checkModule = async (accessModules) => {
    let hasModuleAccess = false;
    if(typeof accessModules !== 'object')  accessModules = [accessModules]
    for(let accessModule of accessModules ){
        if (!hasModuleAccess) {
            await axios
                .get(`check/module/${accessModule}`)
                .then((response) => {
                    hasModuleAccess = response.data.enabled;
                })
                .catch(() => {
                    hasModuleAccess = false
                })
        }
    }
    return hasModuleAccess
}

const routes = [
    /*
   |--------------------------------------------------------------------------
   | Authentication Routes
   |--------------------------------------------------------------------------|
   */

    {
        path: '/account',
        name: 'create-account',
        meta: { redirectIfAuthenticated: true, redirectIfTenant: true },
    },
    {
        path: '/create_account',
        name: 'create_account',
        component: CreateAccountView,
        meta: { redirectIfAuthenticated: true, redirectIfTenant: true },
    },
    {
        path: '/appointment/user',
        name: 'create-appointment_user',
        component: CreateAppointmentView,
        meta: { requiresAuth: true, requiresTenant: true },
    },
    {
        path: '/',
        component: LayoutLoginView,
        meta: { requiresTenant: true },
        children: [
            {
                path: '/',
                component: ConnexionView,
                meta: { redirectIfAuthenticated: true, image: 'login.png' },
            },
            {
                path: 'login',
                name: 'login',
                component: ConnexionView,
                meta: { redirectIfAuthenticated: true, image: 'login.png' },
            },
            {
                path: 'confirm/:id/:token/create_password',
                component: CreatePasswordView ,
                name: 'create-password',
                meta: { redirectIfAuthenticated: false, image: 'resetPassword.png' },
            },

            {
                path: 'forgot-password',
                component:  ForgotPasswordView ,
                name: 'forgot-password',
                meta: { redirectIfAuthenticated: true, image: 'forgetPassword.png' },
            },
            {
                path: 'reset/:token/password',
                component: ResetPasswordView ,
                name: 'reset-password',
                meta: { redirectIfAuthenticated: true, image: 'resetPassword.png' },
            },

        ]

    },
    /*
    |--------------------------------------------------------------------------
    | Admin Backend Routes
    |--------------------------------------------------------------------------|
    */
    {
        path: '/admin',
        name: 'admin',
        redirect: 'admin/dashboard',
        component: LayoutBasicView,
        meta: { requiresAuth: true, requiresTenant: true },
        children: [
            /*
            |--------------------------------------------------------------------------
            | Admin Backend Routes
            |--------------------------------------------------------------------------|
            */
            {
                path: '/',
                component: () => import('@/views/Home/index.vue'),
                name: 'home',
                meta: { requiresPermission: abilities.VIEW_DASHBOARD , requiresAccess: [moduleAbilities.DASHBOARD ] },

            },
            {
                path: 'dashboard',
                component: () => import('@/views/Home/index.vue'),
                name: 'home-path',
                meta: { requiresPermission: abilities.VIEW_DASHBOARD , requiresAccess: [moduleAbilities.DASHBOARD ]  },
            },
            {
                path: 'contacts',
                component: ContactListView,
                name: 'contacts-path',
                meta: { requiresPermission: abilities.VIEW_CONTACT , requiresAccess: [moduleAbilities.CONTACT ]  },
            },
            {
                path: 'contact/:id/view',
                component: ContactDetailView,
                name: 'contact-view-path',
                meta: { requiresPermission: abilities.VIEW_CONTACT },

            },
            {
                path: 'statistic',
                component: () => import('@/views/Statistic/index'),
                name: 'statistics',
                meta: { requiresPermission: abilities.VIEW_STATISTIC , requiresAccess: [moduleAbilities.GENERAL_INVOICE ]  },
            },

            /*
           |--------------------------------------------------------------------------
           | Invoice Routes
           |--------------------------------------------------------------------------|
           */
            {
                path: 'invoices',
                children: [
                    {
                        path: '',
                        component:  () => import('@/views/Invoice/InvoiceList.vue'),
                        name: 'invoices',
                        meta: {requiresPermission: [abilities.VIEW_INVOICE, abilities.VIEW_ALL_INVOICE],
                            requiresAccess: [moduleAbilities.GENERAL_INVOICE, moduleAbilities.CRM_INVOICE]
                        },
                    },
                    {
                        path: 'create',
                        component:  () => import('@/views/Invoice/InvoiceDetail.vue'),
                        name: 'invoices-create',
                        meta: { requiresPermission: abilities.CREATE_INVOICE ,requiresAccess: [moduleAbilities.GENERAL_INVOICE, moduleAbilities.CRM_INVOICE] },
                    },
                    {
                        path: 'edit/:id',
                        component:  () => import('@/views/Invoice/InvoiceDetail.vue'),
                        name: 'invoices-edit',
                        meta: { requiresPermission: [abilities.UPDATE_INVOICE, abilities.UPDATE_ALL_INVOICE] ,requiresAccess: [moduleAbilities.GENERAL_INVOICE, moduleAbilities.CRM_INVOICE] },
                    },
                    {
                        path: 'view/:id',
                        component:  () => import('@/views/Invoice/InvoiceView.vue'),
                        name: 'invoices-view',
                        meta: { requiresPermission: [abilities.VIEW_INVOICE, abilities.VIEW_ALL_INVOICE] ,requiresAccess: [moduleAbilities.GENERAL_INVOICE, moduleAbilities.CRM_INVOICE] },
                    },
                    {
                        path: 'recurrent',
                        component:  () => import('@/views/Invoice/RecurrentInvoiceList.vue'),
                        name: 'recurrent-invoices',
                        meta: { requiresPermission: [abilities.VIEW_RECURRING_INVOICE, abilities.VIEW_ALL_RECURRING_INVOICE],
                            requiresAccess: [moduleAbilities.CRM_INVOICE]},
                    },
                    {
                        path: 'recurrent/create',
                        component:  () => import('@/views/Invoice/RecurrentInvoiceDetail.vue'),
                        name: 'recurrent-invoices-create',
                        meta: { requiresPermission: abilities.CREATE_INVOICE ,requiresAccess: [moduleAbilities.CRM_INVOICE] },
                    },
                    {
                        path: 'recurrent/edit/:id',
                        component:  () => import('@/views/Invoice/RecurrentInvoiceDetail.vue'),
                        name: 'recurrent-invoices-edit',
                        meta: { requiresPermission: [abilities.UPDATE_RECURRING_INVOICE, abilities.UPDATE_ALL_RECURRING_INVOICE] ,
                            requiresAccess: [moduleAbilities.CRM_INVOICE]
                        },
                    },
                    {
                        path: 'recurrent/view/:id',
                        component:  () => import('@/views/Invoice/RecurrentInvoiceView.vue'),
                        name: 'recurrent-invoices-view',
                        meta: { requiresPermission: [abilities.VIEW_RECURRING_INVOICE, abilities.VIEW_ALL_RECURRING_INVOICE],
                            requiresAccess: [moduleAbilities.CRM_INVOICE]
                        },
                    },
                    {
                        path: 'statistic',
                        component:  () => import('@/views/Invoice/Statistic.vue'),
                        name: 'invoices-chart',
                        meta: { requiresPermission: abilities.VIEW_STATS_INVOICE ,requiresAccess: [moduleAbilities.GENERAL_INVOICE, moduleAbilities.CRM_INVOICE]},
                    }
                ]
            },


            /*
           |--------------------------------------------------------------------------
           | Items Routes
           |--------------------------------------------------------------------------|
           */
           {
                path: 'items',
                meta : {requiresAccess: [moduleAbilities.STOCK_ARTICLE]},
                children: [
                    {
                        path: '',
                        component:  () => import('@/views/Items/Product/Index.vue'),
                        name: 'items',
                        meta: { requiresPermission: abilities.VIEW_PRODUCT }
                    },
                    {
                        path: 'details',
                        component:  () => import('@/views/Items/Product/details.vue'),
                        name: 'items-details',
                        meta: { requiresPermission: abilities.CREATE_PRODUCT }
                    },
                    {
                        path: 'attributes',
                        component:  () => import('@/views/Items/Attribute/Index.vue'),
                        name: 'attributes',
                        meta: { requiresPermission: abilities.VIEW_ATTRIBUT },
                    },
                    {
                        path: 'brands',
                        component:  () => import('@/views/Items/Brand/Index.vue'),
                        name: 'brands',
                        meta: { requiresPermission: abilities.VIEW_BRAND },
                    },
                    {
                        path: 'categories',
                        component:  () => import('@/views/Items/Category/Index.vue'),
                        name: 'categories',
                        meta: { requiresPermission: abilities.VIEW_CATEGORY },
                    }
                ]
            },

            /*
             |--------------------------------------------------------------------------
             | Module Routes
             |--------------------------------------------------------------------------|
             */
            {
                path: 'modules',
                children: [
                    {
                        path: '',
                        component:  () => import('@/views/Module/Index.vue'),
                        name: 'modules',
                    }
                ]
            },
            {
                path: 'settings',
                name: 'settings',
                component: () => import('../views/Settings/IndexView.vue'),
                meta: { requiresAuth: true ,requiredPermission : abilities.VIEW_SETTINGS
                },
            },
            {
                name: "settings-system-new",
                path: "settings-society",
                component: () => import('../views/Settings/Layout/BaseSettingProductLayout.vue'),
                children: [
                    {
                        name: "settings-company",
                        path: "company",
                        component: () => import('@/views/Settings/Company/Index.vue'),
                        meta: { requiresPermission: abilities.VIEW_COMPANY },
                    },
                    {
                        name: "settings-taxes",
                        path: "taxes",
                        component: () => import('@/views/Settings/Tax/IndexView'),
                        meta: { requiresPermission: abilities.VIEW_TAX },
                    },
                    {
                        name: "settings-departments",
                        path: "departments",
                        component: () => import('@/views/Settings/User/Department/IndexView'),
                        meta: { requiresPermission: abilities.VIEW_DEPARTMENT },
                    },
                    {
                        name: "settings-department-users",
                        path: "department/:id/users",
                        component: () => import('@/views/Settings/User/Department/UserView'),
                        meta: { requiresPermission: abilities.VIEW_DEPARTMENT },
                    },
                    {
                    name: "settings-post",
                    path: "post",
                    component: () => import('@/views/Settings/Post/index.vue'),
                    meta: {requiresPermission: abilities.VIEW_POST_OFFICE},
                   },
                    {
                        name: "settings-normalization",
                        path: "normalization",
                        component: () => import('@/views/Settings/Normalization/index.vue'),
                        meta: { requiresPermission:  abilities.USER_PERMISSION,requiresAccess: [moduleAbilities.GENERAL_INVOICE ] }
                    },
                    {
                        name: "settings-leaves",
                        path: "leaves",
                        component: () => import('@/Modules/RH/views/Settings/Leaves/Index.vue'),
                        meta: {requiresPermission: abilities.VIEW_POST_OFFICE,requiresAccess: [moduleAbilities.RH_ABSENCE ] },
                    },
                    {
                        name: "settings-leave-create",
                        path: "leaves/create",
                        component: () => import('@/Modules/RH/views/Settings/Leaves/components/CreateHolidayType.vue'),
                        meta: {requiresPermission: abilities.VIEW_POST_OFFICE,requiresAccess: [moduleAbilities.RH_ABSENCE ]},
                    },
                    {
                        name: "settings-leave-update",
                        path: "leaves/update/:id",
                        component: () => import('@/Modules/RH/views/Settings/Leaves/components/CreateHolidayType.vue'),
                        meta: {requiresPermission: abilities.VIEW_POST_OFFICE,requiresAccess: [moduleAbilities.RH_ABSENCE ]},
                    },
                ]
            },
            {
                name: "settings-user",
                path: "settings-user",
                component: () => import('../views/Settings/Layout/BaseSettingUserLayout.vue'),
                children: [
                    {
                        name: "settings-users",
                        path: "users",
                        component: () => import('@/views/Settings/User/User.vue'),
                        meta: { requiresPermission:  abilities.VIEW_USER },
                    },
                    {
                        name: "settings-users-permission-view",
                        path: ':id/view',
                        component: () => import('../views/Settings/User/ViewUserPermission.vue'),
                        meta: { requiresPermission:  abilities.USER_PERMISSION }
                    },
                    {
                        name: "settings-rights",
                        path: "rights",
                        component: () => import('../views/Settings/Right/Right.vue'),
                        meta: { requiresPermission:  abilities.VIEW_RIGHT }
                    },
                    {
                        name: "settings-rights-view",
                        path: 'settings/rights/:id/view',
                        component: () => import('../views/Settings/Right/RightView.vue'),
                        meta: { requiresPermission:  abilities.RIGHT_PERMISSION }
                    }
                ]
            },
            {
                name: "settings-system",
                path: "settings-system",
                component: () => import('../views/Settings/Layout/BaseSettingSystemLayout.vue'),
                children: [
                    {
                        name: "settings-system-mail",
                        path: "system",
                        component: () => import('@/views/Settings/System/index.vue'),
                        meta: { requiresPermission:  abilities.EMAIL_MESSAGE_VIEW }

                    },
                    {
                        name: "settings-reminder",
                        path: "reminder",
                        component: () => import('@/views/Settings/Reminder/index.vue'),
                        meta: { requiresPermission:  abilities.REMIND_CONFIG_VIEW ,requiresAccess: [moduleAbilities.CRM_INVOICE ]}
                    },
                    {
                        name: "settings-smtp",
                        path: "smtp",
                        component: () => import('@/views/Settings/Smtp/index.vue'),
                        meta: { requiresPermission:  abilities.EMAIL_CONFIG_VIEW }
                    },
                    {
                        name: "settings-template",
                        path: "template",
                        component: () => import('@/views/Settings/Template/index.vue'),
                        meta: { requiresPermission:  abilities.TEMPLATE_CONFIG_VIEW,requiresAccess: [moduleAbilities.CRM_INVOICE ] }
                    },
                    {
                        name: "settings-payment",
                        path: "payment",
                        component: () => import('@/views/Settings/Payment/index.vue'),
                        meta: { requiresPermission:  abilities.VIEW_PAYMENT_TYPE ,requiresAccess: [moduleAbilities.CRM_INVOICE ]}
                    },
                    {
                        name: "settings-payment_condition",
                        path: "paymentCondition",
                        component: () => import('@/views/Settings/PaymentCondition/index.vue'),
                        meta: { requiresPermission:  abilities.VIEW_PAYMENT_CONDITION,requiresAccess: [moduleAbilities.CRM_INVOICE ] }
                    },
                ]
            },

            {
                name: "settings-multi-society",
                path: "settings-multi-society",
                component: () => import('../views/Settings/Layout/BaseSettingMultiSocietyLayout.vue'),
                children: [
                    {
                        name: "settings-multiSociety",
                        path: "societies",
                        component: () => import('../views/Settings/MultiSociety/Society.vue'),
                        meta: { requiresPermission:  abilities.EMAIL_MESSAGE_VIEW }

                    },
                    {
                        name: "settings-multiOffice",
                        path: "offices",
                        component: () => import('../views/Settings/MultiSociety/Office.vue'),
                        meta: { requiresPermission:  abilities.REMIND_CONFIG_VIEW }
                    },
                    {
                        name: "settings-office-users",
                        path: "offices/:id/users",
                        component: () => import('@/views/Settings/MultiSociety/Office/UserView'),
                        meta: { requiresPermission: abilities.VIEW_DEPARTMENT },
                    },
                ]
            },
            {
                name: "settings-finance",
                path: "settings-finance",
                component: () => import('../views/Settings/Layout/BaseSettingFinanceLayout.vue'),
                children: [
                    {
                        name: "settings-reason",
                        path: "reason",
                        component: () => import('@/views/Settings/Reason/index.vue'),
                        meta: { requiresPermission:  abilities.VIEW_USER,requiresAccess: [moduleAbilities.FINANCE_EXPENSE ] },
                    },
                    {
                        name: "settings-users-permission-view",
                        path: ':id/view',
                        component: () => import('../views/Settings/User/ViewUserPermission.vue'),
                        meta: { requiresPermission:  abilities.USER_PERMISSION }
                    },
                    {
                        path: "recurrence-expense",
                        component: () => import('@/Modules/Financial/views/Settings/ExpenseRecurrence/Index.vue'),
                        meta: {requiresAccess: [moduleAbilities.FINANCE_EXPENSE ]},
                        children: [
                            {
                                name: "settings-recurrence-expense",
                                path: "",
                                component: () => import('@/Modules/Financial/views/Settings/ExpenseRecurrence/List.vue'),
                            },
                            {
                                name: "settings-recurrence-expense-create",
                                path: ":office/recurrence-expense-create",
                                component: () => import('@/Modules/Financial/views/Settings/ExpenseRecurrence/Detail.vue'),
                            },
                            {
                                name: "settings-recurrence-expense-edit",
                                path: ":id/recurrence-expense-edit",
                                component: () => import('@/Modules/Financial/views/Settings/ExpenseRecurrence/Detail.vue'),
                            },
                            {
                                name: "settings-recurrence-expense-view",
                                path: ":id/recurrence-expense-view",
                                component: () => import('@/Modules/Financial/views/Settings/ExpenseRecurrence/View.vue'),
                            },
                        ]
                    },
                ]
            },
            {
                path: 'crm-settings-pipelines',
                name: 'crm-settings-pipelines',
                component: () => import('@/Modules/CRM/views/Setting/Layouts/BaseSettingPipeline.vue'),
                children: [
                    {
                        name: "crm-settings-pipelines-losse-reasons",
                        path: "losse-reasons",
                        component: () => import('@/Modules/CRM/views/Setting/LosseReasons/index.vue'),
                        meta: { requiresPermission:  abilities.VIEW_REASON,requiresAccess: [moduleAbilities.CRM_PIPELINE ] }
                    },
                    {
                        name: "crm-settings-pipelines-activity-types",
                        path: "activity-types",
                        component: () => import('@/Modules/CRM/views/Setting/ActivityTypes/index.vue'),
                        meta: { requiresPermission:  abilities.VIEW_TYPE_ACTIVITY ,requiresAccess: [moduleAbilities.CRM_PIPELINE ]}

                    },
                    {
                        name: "crm-settings-pipelines-steps",
                        path: "steps",
                        component: () => import('@/Modules/CRM/views/Setting/Steps/index.vue'),
                        meta: { requiresPermission:  abilities.VIEW_OPPORTUNITY_STATE,requiresAccess: [moduleAbilities.CRM_PIPELINE ] }
                    },
                    {
                        name: "crm-settings-pipelines-tag",
                        path: "tag",
                        component: () => import('@/Modules/CRM/views/Setting/Tag/index.vue'),
                        meta: { requiresPermission:  abilities.VIEW_TAG ,requiresAccess: [moduleAbilities.CRM_PIPELINE ]}
                    },
                ]
            },
            {
                path: 'crm-settings-appointments',
                name: 'crm-settings-appointments',
                component: () => import('@/Modules/CRM/views/Setting/Layouts/BaseSettingAppointment.vue'),
                children: [
                    {
                        name: "crm-settings-appointment-type",
                        path: "appointment-type",
                        component: () => import('@/Modules/CRM/views/Setting/AppointmentType/index.vue'),
                        meta: { requiresPermission:  abilities.VIEW_APPOINTMENT_TYPE ,requiresAccess: [moduleAbilities.CRM_APPOINTMENT ]}
                    },


                ]
            },
            CrmRoutes,
            FinancialRoutes,
            RhRoutes,
            FmRoutes,
            NpsRoutes,
            CustomerRoutes,
            ProjectsRoutes,
            ChatsRoutes,
            MailRoutes,
            {
                name: "user-profile",
                path: "user",
                component: () => import('../views/User/LayoutUserAccount/BaseSettingUserLayout.vue'),
                children: [
                    {
                        path: 'profile',
                        component: () => import('@/views/User/Profil.vue'),
                        name: 'profile',
                        meta: { requiresPermission: abilities.VIEW_USER_PROFILE },
                    },
                ]
            },
        ]
    },
    {
        path: '/admin/permission-denied/403',
        name: 'PermissionDenied',
        component: PermissionDeniedView,
    },
    {
        path: '/nps/:id/invite/:invitation',
        name: 'nps-contact-overview',
        component: ContactView,
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFoundPageView
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) => {
    if(to.name !== from.name) {
        store.commit(UPDATE_APP_LOADING_STATUS, true)
        NProgress.start()
    }

    if (to.matched.some(m => m.meta.redirectIfTenant)) {
        if(isSubDomain()) {
            if (!store.getters['auth/isAuthenticated']) {
                return next('/login')
            }else {
                return next('/admin/dashboard')
            }
        }
    }

    if (to.matched.some(m => m.meta.requiresTenant)) {
        if(!isSubDomain()) {
            return next('/create_account')
        }
    }

    if (to.matched.some(m => m.meta.requiresAuth)) {
        if (!store.getters['auth/isAuthenticated']) {
            return next('/login')
        }else{
            await axios.get(`/get/user`).then((response) => {
                if(response.status === 204){
                    store.dispatch('auth/logout', true)
                }
            })
        }
    }

    if (to.matched.some(m => m.meta.redirectIfAuthenticated) && store.getters['auth/isAuthenticated']) {
        return next('/admin/dashboard')
    }

    let accessModule = to.matched[to.matched.length - 1].meta?.requiresAccess;

    if (accessModule) {
        let hasModuleAccess = await checkModule(accessModule);
        if (!hasModuleAccess) {
            return next(`/admin/permission-denied/403`);
        }
    }

    let permissions = to.matched[to.matched.length - 1].meta?.requiresPermission

    if (permissions) {
        let hasAccess = checkPermission(permissions);
        if (!hasAccess ) {
            return next(`/admin/permission-denied/403`);
        }
    }

    return next()

})

router.afterEach(() => {
    NProgress.done()
    store.commit(UPDATE_APP_LOADING_STATUS, false)
})

export default router
