<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <div class="toolbar" id="kt_toolbar">
     <AppSubHeader
          :title="title"
          :breadcrumbs="breadcrumbs"
          :back="true"
          :get-contacts="getContacts ?? []"
          @init-select="initSelect2"
          :query="query"
      >
      </AppSubHeader>
    </div>
    <Loader :isLoading="show">
      <div class="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" class="container" style="max-width: 1550px">
          <div class="row">
            <div class="col-12 col-md-3 col-lg-3 mb-10">
              <div  class="card  mb-5 mb-xl-8">
                <div class="card-body pt-15">
                  <div class="d-flex  flex-column mb-5">
                      <div class="flex-center d-flex  flex-column ">
                        <div class="symbol symbol-150px symbol-circle mb-7" @click="clickOnTab('kt_ecommerce_customer_edit',)">
                          <img :src="contact.avatar_url" alt="image" />
                          <div class="icon-overlay" v-if="contact.contactType == 'particular'" style="background-color: #F07817">
                              <i class="fas fa-user" style="color: white;"></i>
                          </div>
                          <div v-if="contact.contactType == 'society'" class="icon-overlay" style="background-color: #F3DC1F">
                              <i class="fas fa-building" style="color: #000;"></i>
                          </div>
                        </div>
                        <span class="fs-3 text-gray-800 text-center fw-bolder mb-5">
                          {{ contact?.fullname }}
                        </span>
                      </div>
                      <div class="d-flex">
                        <div class="rounded py-2 px-3 me-2 mb-3 phone-card img_user">
                          <span class="svg-icon text-gray-900 svg-icon-muted svg-icon-0hx">
                            <i class="fas fa-user"></i>
                          </span>
                        </div>
                        <div class="bg-gray-100 rounded w-100 py-3 px-3 mx-2 mb-3 phone-value">
                          <div class="fw-bold text-gray-900" v-if="contact.customer || contact.supplier">
                            <span v-if="contact.customer && contact.supplier">
                              {{ $t('contacts.customer') }} & {{ $t('contacts.supplier') }}
                            </span>
                            <span v-else-if="contact.customer"> {{ $t('contacts.customer') }}</span>
                            <span v-else-if="contact.supplier"> {{$t('contacts.supplier') }} </span>
                          </div>
                          <div class="fw-bold text-gray-900" v-else>
                            <span>--------------</span>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex">
                        <div class="rounded py-2 px-3 me-2 mb-3 phone-card img_user">
                          <span class="svg-icon text-gray-900 svg-icon-muted svg-icon-0hx">
                            <i class="fa fa-address-card"></i>
                          </span>
                        </div>
                        <div class="bg-gray-100 rounded w-100 py-3 px-3 mx-2 mb-3 phone-value">
                          <div class="fw-bold text-gray-900" v-if="contact.subcontractor || contact.partner">
                            <span v-if="contact.subcontractor && contact.partner">
                              {{ $t('contacts.subcontractor') }} | {{ $t('contacts.partner') }}
                            </span>
                            <span v-else-if="contact.subcontractor"> {{ $t('contacts.subcontractor') }}</span>
                            <span v-else-if="contact.partner"> {{$t('contacts.partner') }} </span>
                          </div>
                          <div class="fw-bold text-gray-900" v-else>
                            <span>--------------</span>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex">
                        <div class="rounded py-2 px-3 me-2 mb-3 phone-card img_email">
                          <span class="svg-icon text-gray-900 svg-icon-muted svg-icon-0hx">
                            <i class="fas fa-envelope"></i>
                          </span>
                        </div>
                        <div class="bg-gray-100 rounded w-100 py-3 px-3 mx-2 mb-3 phone-value">
                          <div class="fw-bold text-gray-900" v-if="contact.email">
                            {{ contact.email }}
                          </div>
                          <div class="fw-bold text-gray-900" v-else>
                            <span>--------------</span>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex">
                        <div class="rounded py-2 px-3 me-2 mb-3 phone-card img_phone">
                          <span class="svg-icon text-gray-900 svg-icon-muted svg-icon-0hx">
                            <i class="fas fa-phone-alt"></i>
                          </span>
                        </div>
                        <div class="bg-gray-100 rounded w-100 py-3 px-3 mx-2 mb-3 phone-value">
                          <div class="fw-bold text-gray-900" v-if="contact.phone && contact.phone?.length">
                            {{ contact && contact.phone && contact.phone?.length ?
                              contact.phone[0].toString() : "" }}
                          </div>
                          <div class="fw-bold text-gray-900" v-else>
                            <span>--------------</span>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="card card-body pt-6 mb-5">
                <h6 class="mb-2">{{$t('contacts.turnover')}}</h6>
                <h2 class="text-primary">{{formattedLocalAmount(contactStats.invoiceTurnover)}} {{currency?.symbol}}</h2>
              </div>
            </div>

            <div class="col-12 col-md-9 col-lg-9">
              <div class="card pb-0 px-5 mb-8 pt-20">
                <div class="d-flex flex-column flex-md-row justify-content-between">
                  <ul class="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-3 mb-md-0">
                    <li class="nav-item" @click="clickOnTab('kt_customer_stat',true)" v-if="$can(abilities.CONTACT_VIEW_INVOICE) && $canAccess(moduleAbilities.GENERAL_INVOICE)">
                      <a class="nav-link text-active-primary pb-4" :class="{ 'active' : route.query.tab === 'kt_customer_stat' }" data-bs-toggle="tab" href="#kt_customer_stat">
                        {{ $t('invoices.invoice') }} <span class="ms-2 text-success mb-4">{{ invoiceCount }}</span>
                      </a>
                    </li>
                    <li class="nav-item" @click="clickOnTab('kt_estimate',true)"  v-if="$can(abilities.CONTACT_VIEW_ESTIMATE)  && $canAccess(moduleAbilities.CRM_INVOICE)">
                      <a class="nav-link text-active-primary pb-4" :class="{ 'active' : route.query.tab === 'kt_estimate' }" data-bs-toggle="tab" href="#kt_estimate">
                        {{ $t('devis.devis') }} <span class="ms-2 text-success mb-4">{{ deviseCount }}</span>
                      </a>
                    </li>
                    <li class="nav-item" @click="clickOnTab('kt_pipeline',true)" v-if="$can(abilities.CONTACT_VIEW_OPPORTUNITY) && $canAccess(moduleAbilities.CRM_PIPELINE)">
                      <a class="nav-link text-active-primary pb-4"  :class="{ 'active' : route.query.tab === 'kt_pipeline' }" data-bs-toggle="tab" href="#kt_pipeline">
                        {{ $t('crm.pipeline.opportunities') }} <span class="ms-2 text-success mb-4">{{ pipelineCount }}</span>
                      </a>
                    </li>
                    <li class="nav-item" @click="clickOnTab('kt_ecommerce_customer_projects',true)" v-if="$canAccess([moduleAbilities.NOTARIAL_REPERTORY, moduleAbilities.PROJECT])">
                      <a class="nav-link text-active-primary pb-4" :class="{ 'active' : route.query.tab === 'kt_ecommerce_customer_projects' }"  data-bs-toggle="tab" href="#kt_ecommerce_customer_projects">
                        {{ $t('contacts.projects') }} <span class="ms-2 text-success mb-4">{{ projectCount }}</span>
                      </a>
                    </li>
                    <li class="nav-item" @click="clickOnTab('kt_ecommerce_customer_edit')" v-if="$can(abilities.UPDATE_CONTACT)">
                      <a class="nav-link text-active-primary pb-4" :class="{ 'active' : route.query.tab === 'kt_ecommerce_customer_edit' }" data-bs-toggle="tab" href="#kt_ecommerce_customer_edit">{{ $t('contacts.fiche_contact') }}</a>
                    </li>
                    <li class="nav-item" @click="clickOnTab('kt_ecommerce_customer_address')" v-if="$can(abilities.UPDATE_CONTACT)">
                      <a class="nav-link text-active-primary pb-4" :class="{ 'active' : route.query.tab === 'kt_ecommerce_customer_address' }"  data-bs-toggle="tab" href="#kt_ecommerce_customer_address">{{ $t('contacts.address') }}</a>
                    </li>

                  </ul>
                  <div v-if="showStat" class="text-md-end mb-2">
                    <DateRangePicker id="invoices-daterangepicker" @update:date="handleRangeDate" />
                  </div>
                </div>
              </div>

              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show"  :class="{ 'active' : route.query.tab === 'kt_ecommerce_customer_edit' }" id="kt_ecommerce_customer_edit" role="tabpanel" v-if="$can(abilities.UPDATE_CONTACT) && route.query.tab === 'kt_ecommerce_customer_edit'">
                  <UpdateContact :contact="contact" @loadContactData="loadContactData"/>
                </div>

                <div class="tab-pane fade show" :class="{ 'active' : route.query.tab === 'kt_ecommerce_customer_address' }"  id="kt_ecommerce_customer_address" role="tabpanel" v-if="$can(abilities.UPDATE_CONTACT) && route.query.tab === 'kt_ecommerce_customer_address'">
                  <ContactAddress :contact="contact"/>
                </div>

                <div class="tab-pane fade show" :class="{ 'active' : route.query.tab === 'kt_customer_stat' }" id="kt_customer_stat" role="tabpanel" v-if="$can(abilities.CONTACT_VIEW_INVOICE) && $can(abilities.CONTACT_VIEW_INVOICE) && $canAccess(moduleAbilities.GENERAL_INVOICE)">
                  <Invoice :query="query" :currency="currency?.symbol"/>
                </div>

                <div class="tab-pane fade show" :class="{ 'active' : route.query.tab === 'kt_estimate' }"  id="kt_estimate" role="tabpanel" v-if="route.query.tab === 'kt_estimate' && $can(abilities.CONTACT_VIEW_ESTIMATE)  && $canAccess(moduleAbilities.CRM_INVOICE) && $can(abilities.CONTACT_VIEW_ESTIMATE)">
                  <Estimate :query="query" :currency="currency?.symbol" />
                </div>

                <div class="tab-pane fade show" :class="{ 'active' : route.query.tab === 'kt_pipeline' }"  id="kt_pipeline" role="tabpanel" v-if=" route.query.tab === 'kt_pipeline' && $can(abilities.CONTACT_VIEW_OPPORTUNITY) && $can(abilities.CONTACT_VIEW_OPPORTUNITY) && $canAccess(moduleAbilities.CRM_PIPELINE)">
                  <Pipeline :query="query" :currency="currency?.symbol" />
                </div>
                <div class="tab-pane fade show" :class="{ 'active' : route.query.tab === 'kt_ecommerce_customer_projects' }"  id="kt_ecommerce_customer_projects" role="tabpanel" v-if="route.query.tab === 'kt_ecommerce_customer_projects' && $canAccess([moduleAbilities.NOTARIAL_REPERTORY, moduleAbilities.PROJECT])" >
                  <Projects :filters="query"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Loader>
  </div>
</template>

<script setup>
import {computed, onMounted, ref, watch, nextTick} from "vue";
import { useRouter, useRoute } from 'vue-router'
import {useStore} from "vuex";
import AppSubHeader from "@/views/Contact/partials/AppSubViewHeader";
import {formattedLocalAmount} from "@/mixins/Helpers";
import DateRangePicker from "@/components/DateRangePicker.vue";
import Estimate from "./components/Estimate";
import Pipeline from "./components/Pipeline";
import abilities from "@/composables/abilities";
import moduleAbilities from "@/composables/moduleAbilities";
import Projects from "./components/Projects.vue";
import Loader from "@/components/Loader"
import {useHasModuleAccess} from "@/composables/hasModuleInstalled";
import Invoice from "@/views/Contact/components/Invoice.vue";
import ContactAddress from "@/views/Contact/components/ContactAddress.vue";
import UpdateContact from "@/views/Contact/components/UpdateContact.vue";

const router = useRouter()
const route = useRoute()
const store = useStore()
const { canAccess } = useHasModuleAccess()

const title = ref('breadcrumbs.contact.contacts')
const breadcrumbs  = ref([
  {
    name : 'breadcrumbs.contact.list',
    path : '/admin/contacts'
  },
  {
    name : 'breadcrumbs.contact.detail',
    path : ''
  },
])
const showStat = ref(true)
const contact = ref({})

const query = ref({
  status: 'all',
  start_date: '',
  end_date: ''
})

const show = ref(false)
const invoiceCount = ref(0)
const deviseCount = ref(0)
const pipelineCount = ref(0)
const projectCount = ref(0)

const getContacts = computed(() => store.getters['contacts/getContacts'])
const currency = computed(() => store.getters['company/getCompanyCurrency'])
const contactStats = computed(() => store.getters['contacts/getContactStats'])

watch(() => route.params.id, async (value, oldValue) => {
  if (value !== oldValue && route.name === 'contact-view-path') {
    await loadContactData();
    await loadContactStats()
    await loadContactAllStats();
  }
});

watch(() => [query.value.start_date, query.value.end_date], async (value, oldValue) => {
  await loadContactStats()
  await loadContactAllStats();
});

onMounted(async () => {
  show.value = true
  Promise.all([
    loadContactData(),
    loadContactStats(),
    loadContactAllStats(),
  ]).finally(() => {
    initSelect2()
    show.value = false
    if(!route.query.tab){
      if(canAccess(moduleAbilities.GENERAL_INVOICE)){
        clickOnTab('kt_customer_stat',true)
      }else if(canAccess(moduleAbilities.CRM_INVOICE)){
        clickOnTab('kt_estimate',true)
      }else if(canAccess(moduleAbilities.CRM_PIPELINE)){
        clickOnTab('kt_pipeline',true)
      } else if(canAccess([moduleAbilities.PROJECT, moduleAbilities.NOTARIAL_REPERTORY])){
        clickOnTab('kt_ecommerce_customer_projects',true)
      }else{
        clickOnTab('kt_ecommerce_customer_edit')
      }
    }
  })
})

function initSelect2() {
  $("body .kt_select2").select2()
  $('body select[data-control="select2"]').select2()

  $("body .kt_select2").change((e) => {
    switch (e.target.name) {
      case 'contact':
        router.push(`/admin/contact/${e.target.value}/view?tab=${route.query.tab}`)
        break;
      default:
        break;
    }
  });
}

function handleRangeDate (range) {
  query.value.start_date = range.start_date
  query.value.end_date = range.end_date
}

function clickOnTab(tabId, showFilterByDate=false){
  showStat.value = showFilterByDate
  router.replace({ query: { ...route.query, tab: tabId } });
}

async function loadContactData(){
  const res = await store.dispatch('contacts/getContact', route.params.id);
  contact.value = res.data.contact;
}

const loadContactStats = async () => {
  if (query.value.start_date && query.value.end_date) {
    await store.dispatch('contacts/loadContactStats', {
      id: route.params.id,
      startDate: query.value.start_date,
      endDate: query.value.end_date
    }).then(() => show.value = false);
  }
}

const loadContactAllStats = async () => {
  try {
    if(query.value.start_date && query.value.end_date){
      await store.dispatch('contacts/loadContactAllStats', {id: route.params.id, startDate: query.value.start_date, endDate: query.value.end_date}).then((response) => {
        let data = response.data?.data
        invoiceCount.value = data?.invoices_count
        deviseCount.value = data?.estimates_count
        pipelineCount.value = data?.opportunities_count
        projectCount.value = data?.projects_count
      })
    }
  }catch (e) {
  }
}
</script>

<style scoped>
.card-min{
  height: auto!important;
}

.light-danger {
  color: #f1416c;
  border-color: #fff5f8;
  background-color: #fff5f8;
  font-size: .925rem;
  border-radius: 0.325rem;
  padding: 5px 10px;
}

.light-primary {
  color: #2DA333;
  border-color: #f1faff;
  background-color: #f1faff;
  font-size: .925rem;
  border-radius: 0.325rem;
  padding: 5px 10px;
}

.phone-card {
  background-color: #fff !important;
  color: #646E7B !important;
  border-radius: 10px;
  border: 1px solid #646E7B;
  width: 30px;
  height: 30px;
}

.phone-value {
  color: #222222 !important;
  background-color: #F2F3F4 !important;
}

.img-size {
  width: 110px !important;
  height: 110px !important;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
}

.contact-card {
  height: 330px !important;
  border-radius: 10px;
}

.contact-card:hover {
  border: 2px solid var(--bs-primary);
  border-radius: 10px;
}

.badge-light-primary {
    color: var(--bs-primary);
    background-color: var(--bs-light-primary);
}

.image-input [data-kt-image-input-action=change] {
    left: 80%;
    top: 0;
    margin-top: 15px;
}

.remove {
    position: absolute;
    left: 70%;
    top: 80%;
}

.icon-overlay {
    position: absolute;
    bottom: -10px !important;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    border-radius: 50%;
    padding: 8px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
