<template>
  <div class="aside-menu flex-column-fluid" style="overflow-y: auto; max-height: 80vh; overflow-x: hidden !important;">
    <div id="kt_aside_menu_wrapper"
         class="hover-scroll-overlay-y"
         data-kt-scroll="true"
         data-kt-scroll-activate="{default: false, lg: true}"
         data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
         data-kt-scroll-height="auto"
         data-kt-scroll-offset="0"
         data-kt-scroll-wrappers="#kt_aside_menu">

      <div id="kt_aside_menu"
           class="menu menu-column menu-sub-indention fw-semibold fs-6"
           data-kt-menu="true" data-kt-menu-expand="false">
          <template v-for="(section, index) in menu" :key="index">
            <div v-if="section.sectionTitle  && $canAccess(section.module) && $can(section.permission)" @click="setSection(section.sectionTitle)" class="menu-item pt-5" style="cursor: pointer;">
              <div class="menu-link d-flex justify-content-between" :class="isSectionActive(section) && !selectedSection ? 'bg-active-light-primary' : ''">
                <div class="d-flex">
                  <div class="menu-icon">
                    <img :src="section.img" style="width: 100%;"/>
                  </div>
                  <span class="menu-heading menu-title fw-bold text-uppercase fs-7 text-gray-700"> {{ section.sectionTitle }} </span>
                </div>
                <i class="fas fa-chevron-down" v-if="selectedSection == section.sectionTitle"></i>
                <i class="fas fa-chevron-right" v-else></i>
              </div>
            </div>

            <template v-if="section.sectionTitle && selectedSection == section.sectionTitle" v-for="(menuItems, index) in section.children" :key="index">
              <div v-if="menuItems.children.length && $can(menuItems.permission)  && $canAccess(menuItems.module) " class="menu-accordion menu-item pt-5" :class="{ 'show': activateUrl(menuItems.group) }" data-kt-menu-trigger="click">
                <span class="menu-link">
                  <span class="menu-icon mr-3 ms-5">
                    <i class="fs-2" :class="menuItems.icon"></i>
                  </span>
                  <span class="menu-title">{{ menuItems.title }}</span><span class="menu-arrow"></span>
                </span>
                <div class="menu-sub menu-sub-accordion" :class="{ 'show': activateUrl(menuItems.group) }">
                  <div v-for="(items) in menuItems.children" class="menu-item" >
                    <router-link class="menu-link"
                                :class="{ 'active': activateUrl(items.group) }"
                                :to="{name: items.route}"
                                v-if=" $can(items.permission) && $canAccess(items.module)">
                              <span class="menu-bullet">
                                <span class="bullet bullet-dot"></span>
                              </span>
                      <span class="menu-title">{{ items.title }} </span>
                    </router-link>
                  </div>
                </div>
              </div>

              <div v-else class="menu-item pt-5" v-if="$can(menuItems.permission) && $canAccess(menuItems.module)">
                <router-link  class="menu-link" :class="{ 'active': activateUrl( menuItems.group) }" :to="{name: menuItems.route}">
                  <div :id="menuItems?.id" class="menu-icon mr-3 ms-5">
                    <i class="fs-2" :class="menuItems.icon"></i>
                  </div>
                  <span class="menu-title">{{ menuItems.title }}</span>
                </router-link>
              </div>
            </template>

            <template v-if="!section.sectionTitle" v-for="(menuItems, index) in section.children" :key="index">
              <div v-if="menuItems.children.length && $can(menuItems.permission)  && $canAccess(menuItems.module) " class="menu-accordion menu-item pt-5" :class="{ 'show': activateUrl(menuItems.group) }" data-kt-menu-trigger="click">
                <span class="menu-link">
                  <span class="menu-icon mr-3">
                    <i class="fs-2" :class="menuItems.icon"></i>
                  </span>
                  <span class="menu-title">{{ menuItems.title }}</span><span class="menu-arrow"></span>
                </span>
                <div class="menu-sub menu-sub-accordion" :class="{ 'show': activateUrl(menuItems.group) }">
                  <div v-for="(items) in menuItems.children" class="menu-item" >
                    <router-link class="menu-link"
                                :class="{ 'active': activateUrl(items.group) }"
                                :to="{name: items.route}"
                                v-if=" $can(items.permission) && $canAccess(items.module)">
                              <span class="menu-bullet">
                                <span class="bullet bullet-dot"></span>
                              </span>
                      <span class="menu-title">{{ items.title }} </span>
                    </router-link>
                  </div>

                </div>
              </div>

              <div v-else class="menu-item pt-5" v-if="$can(menuItems.permission) && $canAccess(menuItems.module)">
                <router-link  class="menu-link" :class="{ 'active': activateUrl( menuItems.group) }" :to="{name: menuItems.route}">
                  <div :id="menuItems?.id" class="menu-icon mr-3">
                    <i class="fs-2" :class="menuItems.icon"></i>
                  </div>
                  <span class="menu-title ">{{ menuItems.title }}</span>
                </router-link>
              </div>
            </template>
          </template>
      </div>
    </div>
  </div>

  <div class="mx-6 mt-5" v-if="false">
    <router-link class="button d-flex justify-content-center align-items-center" :to="{name: 'modules'}">
      <span class="modules">{{$t('menu.modules')}}</span>
      <span class="module-icon">
        <svg fill="currentColor" viewBox="0 0 24 24" class="icon">
          <path
            clip-rule="evenodd"
            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
            fill-rule="evenodd"
          ></path>
        </svg>
      </span>
    </router-link>
  </div>

  <div class="aside-footer flex-column-auto pt-5 pb-7 px-5" id="kt_aside_footer">
    <div style="padding: 0px!important;" class="btn btn-custom btn-primary w-100" data-bs-toggle="tooltip" data-bs-trigger="hover">
      <span class="btn-label py-3">Version {{ appVersion }}</span>
      <span class="btn-icon" style="padding: 0px!important;">v{{ appVersion }}</span>
    </div>
  </div>
</template>

<script>
import { appVersion } from "@/mixins/Helpers";
import abilities from "@/composables/abilities";
import i18n from "@/plugins/i18n";
import moduleAbilities from "@/composables/moduleAbilities";
import Abilities from "@/composables/abilities";

export default {
  data() {
    return {
      dashboard: ['home', 'home-path'],
      contact: ['contacts-path','contact-view-path'],
      modules: ['modules'],
      settings: ['settings-new', 'settings-taxes', 'settings', 'settings-users', 'settings-rights-view', 'settings-rights', 'settings-departments','settings-system-mail',
      'settings-department-users', "crm-settings-pipelines","crm-settings-pipelines-losse-reasons", "crm-settings-pipelines-activity-types", "crm-settings-pipelines-steps",
      "settings-recurrence-expense", "settings-recurrence-expense-create", "settings-recurrence-expense-edit", "settings-recurrence-expense-view",
      'settings-leaves', 'settings-leave-create', 'settings-leave-update', 'settings-projects-steps', 'settings-projects-status', 'settings-projects-priorities', 
      'settings-projects-categories', 'settings-projects-category-groups'],
      items: ['attributes', 'brands', 'categories', "items", "items-details"],
      attributes: ['attributes'],
      brands: ['brands'],
      categories: ['categories'],
      products: ["items", "items-details"],
      selectedSection: '',
      projectGroup: ['projects-index', 'projects-view', 'tasks-view'],
      reportGroup: ['reports-index'],
      performanceGroup: ['performance-index'],
      pipelineGroup: ['pipelines', "pipelines-new", "pipelines-edit", "pipelines-view","pipelines-steps", "pipelines-steps"],
      paymentGroup: ['payments', "payments-edit", "payments-create", "payments-view"],
      estimatesGroup: ["estimates", "estimates-view", "estimates-edit", "estimates-create"],
      statisticGroup: ["pipelines-chart", "estimates-chart", "invoices-chart", "appointments-chart",'statistics'],
      appointmentGroup: ['appointments', "appointments-edit", "appointments-create", "appointments-view"],
      pipelineChartGroup: ['pipelines-chart'],
      estimatesChartGroup: ['estimates-chart'],
      appointmentsChartGroup: ['appointments-chart'],
      invoiceIndex: ['invoices', "recurrent-invoices"],
      invoices: ['invoices', 'invoices-create', "invoices-edit", "invoices-view"],
      recurrentInvoices: ['recurrent-invoices', 'recurrent-invoices-create', "recurrent-invoices-edit", "recurrent-invoices-view"],
      expenseGroup: ['expenses-summary-stats', 'office-users-expenses-overview','office-expenses','office-users-expenses-list', 'office-user-expense-details',],
      accountGroup: ['finances-accounts', 'finances-accounts-view'],
      expenses: ['expenses', "expenses-new", "expenses-edit", "expenses-view"],
      payslipGroup: ["payslips", "payslips-office-create", "payslip-file"],
      absenceGroup: ['rh-absences', 'rh-absences-view'],
      npsGroup: ['nps',"nps-list", "nps-create", "nps-views", "nps-preview"],
      statistics:  ['statistics']
    }
  },
  computed: {
    menu() {
      return [
        {
          sectionTitle: '',
          children: [
            {
              title: i18n.global.t('menu.home'),
              icon: 'fas fa-home',
              route: 'home-path',
              group: this.dashboard,
              id: 'step-dashboard',
              permission: [abilities.VIEW_DASHBOARD],
              children: [],
              module : moduleAbilities.DASHBOARD,
              query: {}
            },
            {
              title: i18n.global.t('menu.contacts'),
              icon: 'fas fa-users',
              route: 'contacts-path',
              group: this.contact,
              id: 'contacts-path',
              permission: [abilities.VIEW_CONTACT],
              module : moduleAbilities.CONTACT,
              children: [],
              query: {viewType: 'card'}
            },
          ]
        },
        {
          sectionTitle: i18n.global.t('menu.processing.title'),
          img: '/assets/media/suivi_black.png',
          module : [moduleAbilities.NOTARIAL_REPERTORY,moduleAbilities.USER_PERFORMANCE, moduleAbilities.NOTARIAL_REPORT],
          permission: abilities.VIEW_PROJECT,
          children: [
            {
              title: i18n.global.t('menu.processing.folders'),
              icon: 'fas fa-folder icon-right2',
              route: 'projects-index',
              group: this.projectGroup,
              id: 'step-projects',
              permission: [abilities.VIEW_PROJECT],
              children: [],
              module: moduleAbilities.NOTARIAL_REPERTORY,
            },
            {
              title: i18n.global.t('menu.processing.reports'), 
              icon: 'fas fa-file icon-right2',
              route: 'reports-index',
              group: this.reportGroup,
              id: 'step-reports',
              permission: [abilities.VIEW_PROJECT],
              children: [],
              module: moduleAbilities.NOTARIAL_REPORT,
            },
            {
              title: i18n.global.t('menu.processing.performance'),  
              icon: 'fas fa-tachometer-alt icon-right2',
              route: 'performance-index',
              group: this.performanceGroup,
              id: 'step-performance',
              permission: [abilities.VIEW_USER_PERFORMANCE],
              children: [],
              module: moduleAbilities.USER_PERFORMANCE,
            }
          ]
        },
        {
          sectionTitle: i18n.global.t('menu.communicate.title'),
          img: '/assets/media/communicate_black.png',
          module : [moduleAbilities.COMMUNICATION_MESSENGER,moduleAbilities.COMMUNICATION_MAIL],
          permission: abilities.VIEW_CHAT,
          children: [
            {
              title: i18n.global.t('menu.chat.title'),
              icon: 'fa fa-comment icon-right2',
              route: 'chats',
              group: ['chats'],
              id: 'step-chat-view',
              permission: [abilities.VIEW_CHAT],
              children: [],
              module: [moduleAbilities.COMMUNICATION_MESSENGER]
            },
            {
              title: i18n.global.t('menu.mail.title'),
              icon: 'fas fa-envelope icon-right2',
              route: 'mail-index',
              group: ['mail-index'],
              id: 'step-mail',
              permission: [abilities.VIEW_MAIL],
              children: [],
              module: [moduleAbilities.COMMUNICATION_MAIL],
            },
          ]
        },
        {
          sectionTitle: i18n.global.t('menu.explorer.title'),
          img: '/assets/media/explorer_black.png',
          module : moduleAbilities.EXPLORER_DOCUMENT,
          permission: abilities.VIEW_DOCUMENT,
          children: [
            {
              title: i18n.global.t('menu.fm.documents'),
              icon: 'fas fa-folder icon-right2',
              route: 'file-manager-view',
              group: ['file-manager-view'],
              id: 'step-file-manager-view',
              permission: [abilities.VIEW_DOCUMENT],
              children: [],
              module: [moduleAbilities.EXPLORER_DOCUMENT]
            },
          ]
        },
        {
          sectionTitle: "CRM",
          img: '/assets/media/crm_black.png',
          module : [moduleAbilities.CRM_INVOICE ,moduleAbilities.CRM_APPOINTMENT, moduleAbilities.CRM_PIPELINE , moduleAbilities.GENERAL_INVOICE],
          permission: abilities.VIEW_CRM,
          children: [
              {
                  title: i18n.global.t('menu.crm.appointments'),
                  icon: 'fas fa-calendar-alt icon-right1',
                  route: 'appointments',
                  group: this.appointmentGroup,
                  id: 'step-appointment',
                  permission: [abilities.VIEW_APPOINTMENT, abilities.VIEW_ALL_APPOINTMENT],
                  children: [],
                  module: [moduleAbilities.CRM_APPOINTMENT],
                  query: {}
              },
              {
                  title: i18n.global.t('menu.crm.pipeline'),
                  icon: 'fas fa-handshake icon-right2',
                  route: 'pipelines',
                  group: this.pipelineGroup,
                  id: 'step-pipelines',
                  permission: [abilities.VIEW_OPPORTUNITIES, abilities.VIEW_ALL_OPPORTUNITIES],
                  children: [],
                  module: [moduleAbilities.CRM_PIPELINE],
                  query: {viewType: 'list'}
              },
              {
                  title: i18n.global.t('menu.invoices'),
                  icon: ' fas fa-file-invoice-dollar',
                  route: 'invoices',
                  group: this.invoiceIndex,
                  id: 'step-invoices',
                  permission: [abilities.VIEW_INVOICE, abilities.VIEW_ALL_INVOICE, abilities.VIEW_RECURRING_INVOICE, abilities.VIEW_ALL_RECURRING_INVOICE],
                  module: [moduleAbilities.CRM_INVOICE , moduleAbilities.GENERAL_INVOICE],
                  children: [
                    {
                      title: i18n.global.t('menu.invoice_title'),
                      icon: 'fas fa-file-invoice-dollar icon-right1',
                      route: 'invoices',
                      group: this.invoices,
                      id: 'step-invoices',
                      permission: [],
                      children: [],
                      module: [moduleAbilities.CRM_INVOICE , moduleAbilities.GENERAL_INVOICE],
                    },
                    {
                      title: i18n.global.t('menu.recurrent-invoice'),
                      icon: 'fas fa-file-invoice-dollar icon-right1',
                      route: 'recurrent-invoices',
                      group: this.recurrentInvoices,
                      id: 'step-recurrent-invoices',
                      permission: [abilities.VIEW_RECURRING_INVOICE, abilities.VIEW_ALL_RECURRING_INVOICE],
                      children: [],
                      module: [moduleAbilities.CRM_INVOICE],
                    }
                  ],
                  query: {}
              },      
              {
                  title: i18n.global.t('menu.crm.devis'),
                  icon: 'fas fa-file-alt icon-right2',
                  route: 'estimates',
                  group: this.estimatesGroup,
                  id: 'step-estimates',
                  permission: [abilities.VIEW_ESTIMATE, abilities.VIEW_ALL_ESTIMATE],
                  children: [],
                  module: [moduleAbilities.CRM_INVOICE],
                  query: {}
              },
              {
                  title: i18n.global.t('menu.crm.payment'),
                  icon: 'fas fa-file-invoice-dollar icon-right1',
                  route: 'payments',
                  group: this.paymentGroup,
                  id: 'step-payments',
                  permission: [abilities.VIEW_PAYMENT, abilities.VIEW_ALL_PAYMENT],
                  children: [],
                  module: [moduleAbilities.CRM_INVOICE],
                  query: {}
              },
              {
                  title: i18n.global.t('menu.statistics'),
                  icon: 'fas fa-chart-line',
                  route: 'pipelines-chart',
                  group: this.statisticGroup,
                  module: [moduleAbilities.CRM_INVOICE ,moduleAbilities.CRM_APPOINTMENT, moduleAbilities.CRM_PIPELINE , moduleAbilities.GENERAL_INVOICE],
                  id: 'step-crm-statistic',
                  permission: [
                      abilities.VIEW_STATS_ESTIMATE,
                      abilities.VIEW_STATS_OPPORTUNITIES,
                      abilities.VIEW_STATS_APPOINTMENT],
  
                  children: [
                    {
                      title: i18n.global.t('menu.sales'),
                      icon: 'fas fa-chart-line icon-right2',
                      route: 'statistics',
                      group: this.statistics,
                      id: 'step-statistic',
                      permission: [abilities.VIEW_STATISTIC],
                      children: [],
                      module: [moduleAbilities.GENERAL_INVOICE],
                      query: {}
                    },
                      {
                          title: i18n.global.t('menu.crm.pipeline'),
                          icon: 'fas fa-handshake icon-right2',
                          route: 'pipelines-chart',
                          group: this.pipelineChartGroup,
                          id: 'step-chart-pipelines',
                          permission: [abilities.VIEW_STATS_OPPORTUNITIES],
                          children: [],
                          query: {viewType: 'list'},
                          module: [moduleAbilities.CRM_PIPELINE ],
                      },
                      {
                          title: i18n.global.t('menu.crm.devis'),
                          icon: 'fas fa-file-alt icon-right2',
                          route: 'estimates-chart',
                          group: this.estimatesChartGroup,
                          id: 'step-chart-estimates',
                          permission: [abilities.VIEW_STATS_ESTIMATE],
                          children: [],
                          query: {},
                          module: [moduleAbilities.CRM_INVOICE ],
                      },
                      {
                          title: i18n.global.t('menu.crm.appointments'),
                          icon: 'fas fa-calendar-alt icon-right2',
                          route: 'appointments-chart',
                          group: this.appointmentsChartGroup,
                          id: 'step-chart-appointments',
                          permission: [abilities.VIEW_STATS_APPOINTMENT],
                          children: [],
                          query: {},
                          module: [moduleAbilities.CRM_APPOINTMENT],
                      }
                  ],
                  query: {}
              },
          ]
        },
        {
            sectionTitle: i18n.global.t('menu.finance.title'),
            img: '/assets/media/finance_black.png',
            module : [moduleAbilities.FINANCE_EXPENSE, moduleAbilities.FINANCE_ACCOUNT],
            permission: abilities.VIEW_FINANCE,
            children: [
                {
                    title: i18n.global.t('menu.financial.accounts'),
                    icon: 'fas fa-piggy-bank icon-right2',
                    route: 'finances-accounts',
                    group: this.accountGroup,
                    id: 'step-finances-accounts',
                    permission: [abilities.VIEW_ACCOUNT, abilities.VIEW_BOX],
                    children: [],
                    module: [moduleAbilities.FINANCE_ACCOUNT],
                },
                {
                    title: i18n.global.t('menu.finance.my_expenses'),
                    icon: 'fas fa-money-bill-wave icon-right2',
                    route: 'expenses',
                    group: this.expenses,
                    id: 'step-expenses',
                    permission: [abilities.VIEW_EXPENSE],
                    children: [],
                    module: [moduleAbilities.FINANCE_EXPENSE],
                    query: {viewType: 'list'}
                },      
                {
                    title: i18n.global.t('menu.finance.office_expenses'),
                    icon: 'fas fa-file-invoice-dollar icon-right2',
                    route: 'expenses-summary-stats',
                    group: this.expenseGroup,
                    id: 'step-expense-summary',
                    permission: [abilities.APPROVED_EXPENSE, abilities.VALIDATE_EXPENSE, abilities.REJECT_EXPENSE, abilities.PAID_EXPENSE],
                    children: [],
                    module: [moduleAbilities.FINANCE_EXPENSE],
                    query: {viewType: 'list'}
                },
            ]
        },
        {
            sectionTitle: i18n.global.t("menu.rh.title"),
            img: '/assets/media/rh_black.png',
            module : [moduleAbilities.RH_CONTRACT, moduleAbilities.RH_PAYSLIP, moduleAbilities.RH_ABSENCE],
            permission: abilities.VIEW_RH,
            children: [
                {
                    title: i18n.global.t('menu.rh.absences'),
                    icon: 'fas fa-umbrella-beach icon-right2',
                    route: 'rh-absences',
                    group: this.absenceGroup,
                    id: 'step-rh-absences',
                    permission: [abilities.VIEW_HOLIDAY],
                    children: [],
                    module: [moduleAbilities.RH_ABSENCE],
                },
                {
                    title: i18n.global.t('menu.rh.payslips'),
                    icon: 'fas fa-money-check icon-right2',
                    route: 'payslips',
                    group: this.payslipGroup,
                    id: 'step-payslip-summary',
                    permission: [abilities.VIEW_PAYSLIP],
                    children: [],
                    module: [moduleAbilities.RH_PAYSLIP],
                    query: {}
                },
                {
                  title: i18n.global.t('menu.rh.contracts'),
                  icon: 'fas fa-file icon-right2',
                  route: 'contracts',
                  group: ['contracts'],
                  id: 'step-rh-contracts',
                  permission: [Abilities.VIEW_EMPLOYEE_CONTRACT],
                  children: [],
                  module: moduleAbilities.RH_CONTRACT,

                },

            ]
        },
        {
            sectionTitle: i18n.global.t('menu.nps.title'),
            img: '/assets/media/sat_black.png',
            module : moduleAbilities.CUSTOMER_NPS,
            permission: abilities.VIEW_NPS,
            children: [
                {
                    title: i18n.global.t('menu.nps.survey'),
                    icon: 'fas fa-star icon-right2',
                    route: 'nps-list',
                    group: this.npsGroup,
                    id: 'step-nps',
                    permission: [abilities.VIEW_NPS],
                    children: [],
                    module: [moduleAbilities.CUSTOMER_NPS],
                }
            ]
        },
        {
          sectionTitle: '',
          module : '',
          children: [
            {
              title: i18n.global.t('menu.stock'),
              icon: 'fas fa-box',
              route: 'items',
              group: this.items,
              id: 'items-path',
              permission: [
                abilities.VIEW_ATTRIBUT, abilities.VIEW_BRAND, abilities.VIEW_CATEGORY,
                abilities.VIEW_PRODUCT, abilities.VIEW_VARIATION
              ],
              module: [moduleAbilities.STOCK_ARTICLE],
              children: [
                  {
                    title: i18n.global.t('menu.products'),
                    route: 'items',
                    group: this.products,
                    id: 'step-products',
                    permission: [abilities.VIEW_PRODUCT],
                    children: [],
                    module: [],
                  },
                  {
                    title: i18n.global.t('menu.attributs'),
                    route: 'attributes',
                    group: this.attributes,
                    id: 'step-attributs',
                    permission: [abilities.VIEW_ATTRIBUT],
                    children: [],
                    module: [],
                  },
                  {
                    title: i18n.global.t('menu.brands'),
                    route: 'brands',
                    group: this.brands,
                    id: 'step-brands',
                    permission: [abilities.VIEW_BRAND],
                    children: [],
                    module: [],
                  },
                  {
                    title: i18n.global.t('menu.categories'),
                    route: 'categories',
                    group: this.categories,
                    id: 'step-categories',
                    permission: [abilities.VIEW_CATEGORY],
                    children: [],
                    module: [],
                  }
              ],
              query: {}
            },
            {
              title: i18n.global.t('menu.settings'),
              icon: 'fas fa-cog icon-right2',
              route: 'settings',
              group: this.settings,
              id: 'step-setting',
              permission: abilities.VIEW_SETTINGS,
              children: [],
              query: {},
            },
          ]
        },
      ]
    },
    appVersion() {
      return appVersion.value
    }
  },

  methods: {
    activateUrl(group) {
      return group.includes(this.$route.name)
    },
    setSection(section){
      if(this.selectedSection == section){
        this.selectedSection = ''
      }else{
        this.selectedSection = section
      }
    },
    isSectionActive(section) {
      return section.children.some(menuItems =>
        menuItems.children?.some(items => this.activateUrl(items.group)) || 
        this.activateUrl(menuItems.group)
      );
    }
  },
  mounted() {
    const activeSection = this.menu.find(section => this.isSectionActive(section));
    if (activeSection) {
      this.selectedSection = activeSection.sectionTitle;
    }
  }
}
</script>
<style scoped>
  .aside.aside-dark .pos-btn-custom {
    color: #b5b5c3;
    background-color: rgba(63, 66, 84, .35);
  }
  .aside .pos-btn-custom {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }
  .menu-item .menu-link .menu-arrow {
    display: flex;
    align-items: stretch;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    margin-left: .5rem;
    width: .8rem;
    height: .8rem;
    margin-right: 2rem !important;
  }

  .button {
    position: relative;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    padding-block: 0.5rem;
    padding-inline: 1.25rem;
    background-color: var(--bs-primary);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #ffff;
    gap: 10px;
    font-weight: bold;
    border: 3px solid #ffffff4d;
    outline: none;
    overflow: hidden;
    font-size: 15px;
  }

  .button .modules {
    transition: opacity .3s ease;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    opacity: 1;
  }

  .module-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
  }

  .icon {
    width: 24px;
    height: 24px;
    transition: all 0.3s ease-in-out;
    margin-left: -10px;
  }

  .button:hover {
    transform: scale(1.05);
    border-color: #fff9;
  }

  .button:hover .icon {
    transform: translate(4px);
  }

  .button:hover::before {
    animation: shine 1.5s ease-out infinite;
  }

  .button::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 70%
    );
    top: 0;
    left: -100px;
    opacity: 0.6;
  }

  @keyframes shine {
    0% {
      left: -100px;
    }

    60% {
      left: 100%;
    }

    to {
      left: 100%;
    }
  }

  .menu-icon img {
    display: none !important;
    width: 0 !important;
  }

  .menu-heading {
    margin-left: -25px !important;
  }

  [data-kt-aside-minimize="on"] .aside .menu-icon img {
    display: block !important;
    width: 100% !important;
  }

  [data-kt-aside-minimize="on"] .aside:hover .menu-icon img {
    display: none !important;
    width: 0% !important;
  }

  .aside-dark .menu .menu-item.show>.menu-link .menu-icon, 
  .aside-dark .menu .menu-item.show>.menu-link .menu-icon .svg-icon, 
  .aside-dark .menu .menu-item.show>.menu-link .menu-icon i {
    color: #fff !important;
  }

  .aside-dark .menu .menu-item .menu-link.active .menu-icon,
  .aside-dark .menu .menu-item .menu-link.active .menu-icon .svg-icon,
  .aside-dark .menu .menu-item .menu-link.active .menu-icon i {
      color: #fff !important;
  }

  .aside-dark .menu .menu-item .menu-link .menu-icon,
  .aside-dark .menu .menu-item .menu-link .menu-icon .svg-icon,
  .aside-dark .menu .menu-item .menu-link .menu-icon i {
      color: #3B3A3A !important;
  }

  .aside-dark .menu .menu-item.hover:not(.here)>.menu-link:not(.disabled):not(.active):not(.here) .menu-icon,
  .aside-dark .menu .menu-item.hover:not(.here)>.menu-link:not(.disabled):not(.active):not(.here) .menu-icon .svg-icon,
  .aside-dark .menu .menu-item.hover:not(.here)>.menu-link:not(.disabled):not(.active):not(.here) .menu-icon i,
  .aside-dark .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .menu-icon,
  .aside-dark .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .menu-icon .svg-icon,
  .aside-dark .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .menu-icon i {
    color: #3B3A3A !important;
  }

  .bg-active-light-primary {
    background-color: var(--bs-semi-light-primary);
  }
</style>
