import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

const initialState = {
  projects: [],
  status: [],
  transactionTypes: [],
  projectComments: {},
  projectDirectories: {},
  currentProject: {},
  report_status: [],
  step_tasks: [],
  currentProjectLogs: [],
  projectsCollection: [],
  performCriterias: []
}

export default {
  namespaced: true,

  state: initialState,

  getters: getters,

  actions: actions,

  mutations: mutations
}
