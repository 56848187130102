<template>
  <div
      id="notification-drawer"
      class="bg-body drawer drawer-end"
      data-kt-drawer="true"
      data-kt-drawer-name="chat"
      data-kt-drawer-activate="true"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'300px', 'md': '500px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#notification-drawer-btn"
      data-kt-drawer-close="#notification-drawer-close"
     >
    <div class="card w-100 overflow-hidden rounded-0 border-0" id="kt_drawer_chat_messenger">
      <div class="card-header pe-5" id="kt_drawer_chat_messenger_header">
        <div class="card-title">
          <h3> {{ $t('notification.title') }} </h3>
        </div>
        <div class="card-toolbar">
          <div class="btn btn-sm btn-icon" id="notification-drawer-close">
            <span class="svg-icon svg-icon-2">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
									<rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
								</svg>
							</span>
          </div>
        </div>
      </div>

      <div class="card-body px-4" id="kt_drawer_chat_messenger_body">
        <div id="notification-list-container" :class="{'scroll-y' : notifications.length > 0 }" class="me-n5 pe-5" data-kt-element="messages"
             data-kt-scroll="true"
             data-kt-scroll-activate="true"
             data-kt-scroll-height="auto"
             data-kt-scroll-dependencies="#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer"
             data-kt-scroll-wrappers="#kt_drawer_chat_messenger_body" data-kt-scroll-offset="0px"
             style="height: 90vh"
             >

          <div class="d-flex flex-column mb-10">
            <div class="d-flex flex-column flex-grow-1 m-2">
              <div  v-if="!notifications.length" class="text-center fs-3"> {{  $t('notification.empty') }}</div>
              <Card v-for="notification in notifications" class="w-auto p-2 shadow mb-3 cursor-pointer" @click.prevent="read(notification)">
                <div class="d-flex flex-stack py-4">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-35px me-4">
                      <span :class="`symbol-label bg-light-${notification.color} rounded-circle h-40px w-40px d-flex justify-content-center align-items-center`">
                        <i :class="`${notification.icon} text-${notification.color}`"></i>
                      </span>
                    </div>
                    <div class="mb-0 me-2 d-flex flex-column align-self-stretch ms-4">
                      <small :class="`text-${notification.color} fs-3`">{{ notification.title }} </small>
                      <p class="fs-5" :class="{'fw-bolder' : !notification.data_read }" > {{ notification.message }} </p>
                      <span class="text-muted fw-bold">{{ formatNotificationDate(notification.created_at) }} </span>
                    </div>
                  </div>
                  <div class="d-flex align-items-end ms-auto w-15px" v-if="!notification.data_read">
                    <span :class="`badge badge-${notification.color} badge-circle w-10px h-10px me-1`"></span>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div class="text-center" v-if="isLoading">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{ $t("loadingText") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import Card from "@/Modules/Financial/components/Card.vue";

import {formatNotificationDate} from "@/mixins/Helpers";
import {computed, nextTick, onMounted, reactive, ref, watch, watchEffect} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

const props = defineProps(
  {
    notifications: {
      type: Array,
      default: () => []
    }
  })

const store = useStore()
const router = useRouter()

const currentPage = computed(() => store.getters['notifications/currentPage'] )
const lastPage = computed(() => store.getters['notifications/lastPage'] )
const isLoading = ref(false)

const drawerElement = ref(null);
const drawer = ref(null);

function initDrawer() {
  nextTick(() => {
    drawerElement.value = document.querySelector("#notification-drawer");
    if (drawerElement.value) {
      drawer.value =  KTDrawer.getInstance(drawerElement.value);
      drawer.value?.on("kt.drawer.show", function() {
        document.querySelectorAll('.drawer-overlay').forEach(el => el.remove());
        store.dispatch('notifications/openedNotifications')
      });
    }
  });
}

watchEffect(() => {
  initDrawer();
});



const loadNextPage = () => {
  const container = document.getElementById('notification-list-container');

  container.addEventListener('scroll', async() => {
    if (container.scrollTop + container.clientHeight >= container.scrollHeight - 50) {
      isLoading.value = true;
      if(currentPage.value < lastPage.value){
        await store.dispatch('notifications/loadNotifications', currentPage.value + 1)
      }
      isLoading.value = false
    }
  });
};

async function read(notification){
  await store.dispatch('notifications/readNotification', notification.id)
      .finally(() => {
        if(notification.link){
          drawer.value?.toggle()
          router.push(notification.link)
        }
         return false
      })
}

onMounted(async () => {
  loadNextPage()
  await KTDrawer.init();
  initDrawer();
})

</script>

<style scoped>

</style>