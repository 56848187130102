<template>
  <BaseGlobalLoader v-if="isLoaded"/>
  <router-view v-else/>
  <component is="script" :src="MEET_BASE_API_URL" async></component>
</template>


<script setup>
import 'nprogress/nprogress.css'
import '../public/assets/css/nprogress.custom.css'
import BaseGlobalLoader from '@/components/BaseGlobalLoader'
import {computed, nextTick, onMounted, watch} from 'vue';
import {useStore} from "vuex";
import {getImagePath, getTenantId} from './mixins/Helpers';
import { useRoute } from 'vue-router';
import {MEET_BASE_API_URL} from "@/services/meetService";
const store = useStore()
const isLoaded = computed(()=> store.getters['isAppLoaded'])

const route = useRoute();

const updateMeta = async () => {
  const tenantId = getTenantId()?.slice(0, -1);

  document.title = `${tenantId?.toUpperCase() || "BY+"}`;
  const favicon = document.querySelector("link[rel='icon']") || document.createElement("link");
  favicon.rel = "icon";
  getImagePath('favicon.png').then((path) => favicon.href=path );
  document.head.appendChild(favicon);
};

onMounted(() => {
  nextTick(() => updateMeta())
});

watch(route, updateMeta);

</script>
<style scoped>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}


</style>
