<template>
  <div class="card" :class="[containerClass, getVariantColors(variant)]">
    <slot name="cardHeader" />
    <div v-if="(title || $slots.cardToolbar) && !$slots.cardHeader" class="card-header">
      <h2 v-if="title" class="card-title " :class="titleClass">{{ translate ? $t(title) : title }}
       
      </h2>
      <div class="card-toolbar">
        <slot name="cardToolbar" />
      </div>
    </div>
    <slot />
    <div v-if="$slots.cardBody" class="card-body">
      <slot name="cardBody"/>
    </div>
    <div v-if="$slots.cardFooter" class="card-footer">
      <slot name="cardFooter"/>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'

defineProps({
  title: String,
  containerClass: { type: String, default: 'card shadow-sm' },
  variant: {type: String, default: 'default'},
  titleClass: { type: String, default: '' },
  translate: {type: Boolean, default: true}
})

function getVariantColors(variant= 'default') {
  const colors = {
    default: '',
    white: '',
    dark: 'card-variant-dark',
    gray: 'card-variant-gray',
    danger: 'card-variant-danger',
    success: 'card-variant-success',
    primary: 'card-variant-primary',
    warning: 'card-variant-warning',
    info: 'card-variant-info'
  }

  return colors[variant]

}

</script>

<style scoped>

.card-variant-dark {
  color: #fff !important;
  background-color: #212529 !important;
}

.card-variant-dark .card-header, .card-variant-dark .card-body{
  color: #fff!important;
  border-color: #1b1e22;
}

.card-variant-dark .card-title {
  color: #fff!important;
}

.card-variant-gray {
  color: #000 !important;
  background-color: #F2F3F4 !important;
}

.card-variant-gray .card-header, .card-variant-gray .card-body{
  color: #000!important;
  border-color: #CCCFD3;
}

.card-variant-gray .card-title {
  color: #000!important;
}

.card-variant-danger {
  color: #fff !important;
  background-color: #E51335CC !important;
}

.card-variant-danger .card-header, .card-variant-danger .card-body{
  color: #000!important;
  border-color: #b02b38;
}

.card-variant-danger .card-title {
  color: #fff!important;
}

.card-variant-success {
  color: #fff !important;
  background-color: #2DA333 !important;
}

.card-variant-success .card-header, .card-variant-success .card-body{
  color: #fff!important;
  border-color: #156d44;
}

.card-variant-success .card-title {
  color: #fff!important;
}

.card-variant-primary {
  color: #fff !important;
  background-color: var(--bs-primary) !important;
}

.card-variant-primary .card-header, .card-variant-primary .card-body{
  color: #fff!important;
  border-color: var(--bs-primary);
}

.card-variant-primary .card-title {
  color: #fff!important;
}

.card-variant-warning {
  color: #fff !important;
  background-color: var(--bs-warning) !important;
}

.card-variant-warning .card-header, .card-variant-warning .card-body{
  color: #fff!important;
  border-color: var(--bs-warning);
}

.card-variant-warning .card-title {
  color: #fff!important;
}

.card-variant-info {
  color: #fff !important;
  background-color: var(--bs-info) !important;
}

.card-variant-info .card-header, .card-variant-info .card-body{
  color: #fff!important;
  border-color: var(--bs-info);
}

.card-variant-info .card-title {
  color: #fff!important;
}

</style>
