export const getProjects = (state) => state.projects
export const getProjectsCollection = (state) => state.projectsCollection
export const getProjectStatus = (state) => state.status
export const getBudgetTransactionTypes = (state) => state.transactionTypes
export const getCurrentProject = (state) => state.currentProject
export const getCurrentProjectLogs = (state) => state.currentProjectLogs
export const getProjectComments = (state) => (id)  => state.projectComments[id] || []
export const getProjectDirectories = (state) => (id)  => state.projectDirectories[id] || []
export const getReportStatus = (state) => state.report_status
export const getProjectStepTasks = (state) => state.step_tasks
export const getUserPerformCriterias = (state) => state.performCriterias

